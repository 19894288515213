import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { ReceiptModule } from '../receipt/receipt.module';
import { PaymentCardComponent } from '../../components/shop/payment-card/payment-card.component';
import { AcceptedCreditCardsComponent } from '../../components/shop/accepted-credit-cards/accepted-credit-cards.component';


@NgModule({
  declarations: [
    PaymentCardComponent,
    AcceptedCreditCardsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
    ReceiptModule,
  ],
  exports: [
    PaymentCardComponent,
  ]
})
export class PaymentCardModule { }
