import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleButtonComponent } from '../../components/general/simple-button/simple-button.component';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { CountButtonComponent } from '../../components/general/count-button/count-button.component';
import { VirtualKeyboardComponent } from '../../components/general/virtual-keyboard/virtual-keyboard.component';
import { OnScreenKeyboardComponent } from '../../components/general/on-screen-keyboard/on-screen-keyboard.component';
import { FormsModule } from '@angular/forms';
import { MessageComponent } from '../../components/general/message/message.component';
import { PinKeyboardComponent } from '../../components/general/pin-keyboard/pin-keyboard.component';
import { CashKeyboardComponent } from '../../components/general/cash-keyboard/cash-keyboard.component';
import { VirtualKeypadComponent } from '../../components/general/virtual-keypad/virtual-keypad.component';
import { TicketAccessStatusComponent } from '../../components/ticket/ticket-access-status/ticket-access-status.component';
import { TicketStatusComponent } from '../../components/ticket/ticket-status/ticket-status.component';

@NgModule({
  declarations: [
    SimpleButtonComponent,
    CountButtonComponent,
    VirtualKeyboardComponent,
    VirtualKeypadComponent,
    OnScreenKeyboardComponent,
    MessageComponent,
    PinKeyboardComponent,
    CashKeyboardComponent,
    TicketStatusComponent,
    TicketAccessStatusComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    VUTranslateModule,
  ],
  exports: [
    SimpleButtonComponent,
    CountButtonComponent,
    VirtualKeyboardComponent,
    VirtualKeypadComponent,
    OnScreenKeyboardComponent,
    MessageComponent,
    PinKeyboardComponent,
    CashKeyboardComponent,
    TicketStatusComponent,
    TicketAccessStatusComponent,
  ]
})
export class ComponentsModule { }
