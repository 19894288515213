import { Injectable } from '@angular/core';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { Product, Money } from '../../../../../lib/lib';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { SaleService } from '../../../../sale.service';

@Injectable()
export class CardPaymentAmountInputGridWorkflowService extends BaseGridWorkflowService {
  private saleService: SaleService;

  init(
    ) {
      super.init();
      this.saleService = this.injector.get(SaleService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardPaymentAmountInput;
  }

  get minAmount(): Money {
    if (this.displayItem && this.displayItem.minAmount > 0) {
      return new Money(this.displayItem.minAmount, 'EUR');
    }
    return Money.empty;
  }

  get maxAmount(): Money {
    if (this.displayItem && this.displayItem.maxAmount > 0) {
      return new Money(this.displayItem.maxAmount, 'EUR');
    }
    return Money.empty;
  }

  get product(): Product {
    if (!this.displayItem) {
      return null;
    }
    return this.displayItem.product;
  }

  amountChanged(amount: number = 0, product: Product = null): void {
    this.userActivity();
    if (this.displayItem == null) {
      return;
    }
    this.displayGridWorkflowService.updateContext({
      cardPaymentAmount: new Money(amount, 'EUR'),
      cardPaymentProduct: product
    });
    this.updateNextButton(amount);
  }

  updateNextButton(amount: number) {
    let isPaymentButtonAvailable = false;
    if (this.displayItem != null && amount > 0) {
      if (amount >= this.displayItem.minAmount && amount <= this.displayItem.maxAmount && amount % 1 === 0) {
        isPaymentButtonAvailable = true;
      }
    }
    this.displayGridWorkflowService.changeIsDisabledState('confirm_cash_payment_amount', !isPaymentButtonAvailable);
    this.displayGridWorkflowService.changeIsDisabledState('confirm_card_payment_amount', !isPaymentButtonAvailable);
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    this.saleService.closePaymentSession();
    this.updateNextButton(0);
  }
}
