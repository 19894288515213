import { MachineBaseService } from '../machine-base.service';
import { SaleService } from '../../sale.service';
import {
  PaymentSession,
  Money,
  OrderSaveResult,
  PrintTaskResult,
  PrintTask,
  PrintTaskType,
  MachineInactivitySettings
} from '../../../lib/lib';
import { MessageType, Message } from '../../message.service';
import { LiteDisplayService } from '../../lite/lite-display.service';
import { PrinterHelper } from '../../../lib/printer/printer-helper';
import { LiteTouchTileService } from '../../lite/lite-touch-tile.service';
import { TurnstileService } from '../../turnstile/turnstile.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MachineLitePostSaleService extends MachineBaseService {
  private saleService: SaleService;
  private paymentSession: PaymentSession;
  private isSessionClosed = false;
  private liteDisplayService: LiteDisplayService;
  private liteTouchTileService: LiteTouchTileService;
  private turnstileService: TurnstileService;

  init(): void {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.vuConnection.eventReturnChangeFinished.subscribe(() => this.onReturnChangeFinished());
    this.vuConnection.eventOrderSaveResultReceived.subscribe((x: OrderSaveResult) => this.onOrderSaveResult(x));
    this.vuConnection.eventPrintTaskResultReceived.subscribe((x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
    this.vuConnection.eventPrintTicketRemoved.subscribe((x: PrintTaskResult) => this.onPrintTicketRemoved(x));
    this.liteDisplayService = this.injector.get(LiteDisplayService);
    this.liteTouchTileService = this.injector.get(LiteTouchTileService);
    this.turnstileService = this.injector.get(TurnstileService);
    this.turnstileService.eventTurnstileEnter.subscribe(() => this.onTurnstileEnter());
  }

  get machineName(): string {
    return 'Lite Post Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toFlowBegin', from: ['off'], to: 'flowBegin' },
      { name: 'toWaitingForMoney', from: ['flowBegin'], to: 'waitingForMoney' },
      { name: 'toWaitingForMoneyTimeout', from: ['waitingForMoney'], to: 'waitingForMoneyTimeout' },
      { name: 'toCancelled', from: ['waitingForMoneyTimeout'], to: 'cancelled' },
      {
        name: 'toReturningAmountPaid', from: [
          'cancelled',
          'returningAmountChange',
          'saveOrder',
          'printTickets',
          'waitingForTurnstileEnter'
        ], to: 'returningAmountPaid'
      },
      { name: 'toCheckForChange', from: ['waitingForMoneyTimeout'], to: 'checkForChange' },
      { name: 'toReturningAmountChange', from: ['checkForChange'], to: 'returningAmountChange' },
      { name: 'toSaveOrder', from: ['checkForChange', 'returningAmountChange'], to: 'saveOrder' },
      { name: 'toPrintTickets', from: ['saveOrder'], to: 'printTickets' },
      { name: 'toPrintReceipt', from: ['printTickets'], to: 'printReceipt' },
      { name: 'toWaitingForTurnstileEnter', from: ['saveOrder', 'printTickets'], to: 'waitingForTurnstileEnter' },
      { name: 'toFlowEnd', from: ['*'], to: 'flowEnd' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff: (event: any, isHardReset: false) => {
        scope.paymentSession = null;
        scope.dispatcherService.isBackButtonEnabled = true;
      },
      onToFlowBegin: () => {
        scope.isSessionClosed = false;
        scope.dispatcherService.isBackButtonEnabled = false;
        scope.paymentSession = scope.saleService.paymentSession;
        scope.log.info(`MachinePostSaleService. onToFlowBegin. ${scope.paymentSession}`);

        scope.doAsync(() => {
          scope.machine.toWaitingForMoney();
        }, 'onToFlowBegin');
      },
      onToWaitingForMoney: () => {
        const toWaitingForMoneyTimeout = () =>
          scope.doAsync(() => scope.machine.toWaitingForMoneyTimeout(), 'onToWaitingForMoney');

        if (scope.paymentSession.isCancelled) {
          scope.closeRemoteTransaction(false, 'Canceled.', toWaitingForMoneyTimeout);
        } else {
          toWaitingForMoneyTimeout();
        }
      },
      onToWaitingForMoneyTimeout: () => {
        if (scope.paymentSession.isCancelled) {
          scope.doAsync(() => scope.machine.toCancelled(), 'onToWaitingForMoneyTimeout. toCancelled');
        } else {
          scope.doAsync(() => scope.machine.toCheckForChange(), 'onToWaitingForMoneyTimeout. toCheckForChange');
        }
      },
      onToCancelled: () => {
        scope.dispatcherService.workflowDeleteLastStep();
        if (scope.paymentSession.amountPaidIn.isZero) {
          scope.dispatcherService.isBackButtonEnabled = true;
          scope.doAsync(() => scope.machine.toOff(), 'onToCancelled. amountPaid.isZero');
        } else {
          scope.doAsync(() => scope.machine.toReturningAmountPaid(),
            'onToCancelled. toReturningAmountPaid');
        }
      },
      onToReturningAmountPaid: () => {
        scope.do(() => {
          scope.liteDisplayService.changeCoins();
          scope.liteTouchTileService.returningAmountPaid();
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountPaidIn
            .distract(scope.paymentSession.amountChangePaidOut);

          const amountPaidOut = scope.paymentSession.amountPaidOut;
          const isCancelled = scope.paymentSession.isCancelled;
          scope.dispatcherService.cashDevicesStatePayOut(true);

          if (isCancelled && !amountPaidOut.isZero) {
            scope.doAsync(() => {
              scope.onMoneyChanged(amountPaidOut.negate());
            }, 'onToReturningAmountPaid. isCancelled && !amountPaidOut.isZero');
          }

          if (isCancelled) {
            scope.closeRemoteTransaction(false, 'Canceled with incomming payment.');
          }
        }, 'onToReturningAmountPaid');
      },
      onToCheckForChange: () => {
        if (scope.paymentSession.amountChange.isPositive) {
          scope.doAsync(() => scope.machine.toReturningAmountChange(),
            'onToCheckForChange. toReturningAmountChange');
        } else {
          scope.doAsync(() => scope.machine.toSaveOrder(),
            'onToCheckForChange. toSaveOrder');
        }
      },
      onToReturningAmountChange: () => {
        scope.liteDisplayService.changeCoins();
        scope.liteTouchTileService.returningChange();
        scope.do(() => {
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountChange;
          scope.dispatcherService.cashDevicesStatePayOut(true);
          scope.doAsync(() => scope.checkAmountPaidOut(), 'onToReturningAmountChange');
        }, 'onToReturningAmountPaid');
      },
      onToSaveOrder: () => {
        scope.liteTouchTileService.setTicketInPrinter();
        scope.liteTouchTileService.ticketPrinting();
        scope.liteDisplayService.printTicket();
        if (scope.turnstileService.fastOpen) {
          scope.turnstileService.openEnter();
        }
        const order = scope.saleService.order;
        scope.vuHttp.saveOrder(order).catch(reason => {
          scope.onOrderSaveResult(new OrderSaveResult(0,
            scope.saleService.order.uid, false, reason));
        });
      },
      onToPrintTickets: () => {
        scope.liteTouchTileService.setTicketInPrinter();
        scope.liteTouchTileService.ticketPrinting();
        scope.liteDisplayService.printTicket();
        scope.vuHttp.print(new PrintTask(scope.saleService.order.id, PrintTaskType.Ticket))
          .catch((x) => {
            scope.onPrintTicketsResultReceived(false);
          });
      },
      onToPrintReceipt: () => {
        scope.vuHttp.print(new PrintTask(scope.saleService.order.id, PrintTaskType.Receipt));
      },
      onToWaitingForTurnstileEnter: () => {
        scope.liteDisplayService.go();
        scope.liteTouchTileService.turnstileEnterAllowed();
        if (!scope.turnstileService.fastOpen) {
          scope.turnstileService.openEnter();
        }
      },
      onToFlowEnd: () => {
        scope.dispatcherService.isBackButtonEnabled = true;
        // scope.isBackToHomeAfterSale = true;
        scope.closeRemoteTransaction(true, 'Order saved. Role exit.');
        scope.doAsync(() => scope.machine.toOff(), 'onToFlowEnd');
      },
    });
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (state === 'flowEnd') {
          this.machine.toOff();
        }
        if (state === 'waitingForTurnstileEnter') {
          this.closeTurnstileWithoutReturningMoney();
        }

        if (state === 'returningAmountPaid') {
          this.machine.toFlowEnd();
        }
        break;
      default:
        break;
    }
    return false;
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toFlowBegin();
    }
  }

  private onMoneyChanged(money: Money): void {
    switch (this.state) {
      case 'returningAmountPaid':
        this.dispatcherService.workflowLastStepUpdate(... this.getPayoutArgs());
        if (this.paymentSession.isAmountPaidPaidOut) {
          this.onRequestedPayoutComplete(() => this.machine.toFlowEnd());
        }
        break;
      case 'returningAmountChange':
        const m = `totalPaidOut: ${this.paymentSession.amountPaidOut}. amountChange: ${this.paymentSession.amountChange}`;
        this.log.info(`MachinePostSaleService. ${m}`);
        this.checkAmountPaidOut();
        break;
      default:
        break;
    }
  }

  private onReturnChangeFinished(): void {
    const scope = this;
    setTimeout(() => {
      scope.onReturnChangeFinishedWithDelay();
    }, 1000); // to avoid the racing with the last eventMoneyChanged event
  }

  private onReturnChangeFinishedWithDelay(): void {
    if (this.state !== 'returningAmountChange') {
      this.log.info(`onReturnChangeFinished. Skip becase ${this.state} != 'returningAmountChange'`);
      return;
    }

    if (this.paymentSession.isAmountChangePaidOut) {
      this.log.info(`onReturnChangeFinished. Skip becase isAmountChangePaidOut`);
      return;
    }

    this.log.warning(`onReturnChangeFinished. Change not paid out!`);
    this.cancelSessionAndReturnMoney();
  }

  private checkAmountPaidOut(): void {
    if (this.paymentSession.isAmountChangePaidOut) {
      this.paymentSession.amountChangePaidOut = this.paymentSession.amountPaidOut;
      this.paymentSession.resetAmountTempPayout();

      this.onRequestedPayoutComplete(() => this.machine.toSaveOrder());
    }
  }

  private onRequestedPayoutComplete(machineSwitch: () => void): void {
    this.dispatcherService.cashDevicesStatePayOut(false);
    machineSwitch();
  }

  private onOrderSaveResult(result: OrderSaveResult): void {
    if (this.state !== 'saveOrder') {
      return;
    }
    result = OrderSaveResult.fromOther(result);
    this.saleService.order.id = result.orderId;
    const m = `MachinePostSaleService. onOrderSaveResult: ${result}`;
    if (result.isSuccess) {
      this.log.info(m);
    } else {
      this.log.error(m);
    }
    const order = this.saleService.order;
    if (result.isSuccess
      && result.orderUid === order.uid
      && result.orderId != null) {
      if (this.isExitRole) {
        this.machine.toFlowEnd();
      } else {
        this.machine.toPrintTickets();
      }
    } else {
      this.paymentSession.isCancelled = true;
      if (this.turnstileService.fastOpen) {
        this.turnstileService.closeEnter();
      }
      this.machine.toReturningAmountPaid();
    }
  }

  private onPrintTaskResultReceived(result: PrintTaskResult): void {
    if (!result.isSuccess) {
      this.completePrintTask(result);
    }

    const waitForTicketRemoved = this.waitForTicketRemoved(result);
    if (!waitForTicketRemoved) {
      this.completePrintTask(result);
    }
  }

  private onPrintTicketRemoved(result: PrintTaskResult): void {
    this.liteTouchTileService.resetTicketInPrinter();
    if (this.isInState('waitingForTurnstileEnter')) {
      this.liteTouchTileService.turnstileEnterAllowed();
    }
    if (this.additionalPropertiesConfigurationService.waitForTicketRemoved) {
      this.completePrintTask(result);
    }
  }

  private waitForTicketRemoved(result: PrintTaskResult): boolean {
    return this.additionalPropertiesConfigurationService.waitForTicketRemoved
      && this.isPresenter(result);
  }

  private isPresenter(result: PrintTaskResult): boolean {
    const currentReceiptPrinterType = this.configurationService.configuration.receiptPrinterType;
    const currentTicketPrinterType = this.configurationService.configuration.ticketPrinterType;
    return result
      && result.printTask
      &&
      (
        result.printTask.printTaskType === PrintTaskType.Receipt && PrinterHelper.isPrinterPrisenter(currentReceiptPrinterType)
        ||
        result.printTask.printTaskType === PrintTaskType.Ticket && PrinterHelper.isPrinterPrisenter(currentTicketPrinterType)
      );
  }

  private completePrintTask(result: PrintTaskResult): void {
    if (result.printTask.printTaskType === PrintTaskType.Ticket) {
      this.onPrintTicketsResultReceived(result.isSuccess);
    } else {
      this.onPrintReceiptResultReceived(result.isSuccess);
    }
  }

  private onPrintTicketsResultReceived(result: boolean): void {
    if (this.state !== 'printTickets') {
      return;
    }
    this.log.info(`MachinePostSaleService. onPrintTicketsResultReceived. result: ${result}`);
    if (result) {
      if (this.saleService.order.isReceipt) {
        this.machine.toPrintReceipt();
      } else if (!this.turnstileService.isEnterAllowed && this.turnstileService.fastOpen) {
        this.machine.toFlowEnd();
      } else {
        this.machine.toWaitingForTurnstileEnter();
      }
    } else {
      this.liteTouchTileService.resetTicketInPrinter();
      this.paymentSession.isCancelled = true;
      this.vuHttp.cancelOrder(this.saleService.order);
      this.machine.toReturningAmountPaid();
    }
  }

  private onPrintReceiptResultReceived(result: boolean): void {
    if (this.state !== 'printReceipt') {
      return;
    }
    this.log.info(`MachinePostSaleService. onPrintReceiptResultReceived. result: ${result}`);
    this.machine.toFlowEnd();
  }

  protected get timeoutTrackingStates(): string[] {
    return this.getsAllStatesExceptFor('off');
  }

  private getPayoutArgs(): string[] {
    try {
      const result = [
        this.paymentSession.amountTempToPayOut.toStringCurrencySign(),
        this.paymentSession.amountTempPaidOut.toStringCurrencySign()
      ];
      return result;
    } catch (e) {
      this.log.error(this.paymentSession);
    }
    return ['?', '?'];
  }

  closeRemoteTransaction(isToCommit: boolean, context: string, callback: any = null): void {
    this.saleService.remoteTransaction.closeRemoteTransaction(isToCommit, context, callback == null ? null : callback.bind(this));
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName) {
      if (this.isInState(
        'waitingForMoney',
        'returningAmountChange',
        'saveOrder',
      )) {
        this.cancelSessionAndReturnMoney();
        return;
      }

      if (this.isInState(
        'printTickets',
      )) {
        this.machine.toWaitingForTurnstileEnter();
        return;
      }

      if (this.isInState(
        'waitingForTurnstileEnter',
      )) {
        this.closeTurnstileWithoutReturningMoney();
        return;
      }
    }

    this.closeRemoteTransaction(false, 'timeout');
    super.onMachineTimeoutModalCancel(machineName);
  }

  private cancelSessionAndReturnMoney(): void {
    this.turnstileService.closeEnter();
    this.paymentSession.isCancelled = true;
    this.machine.toReturningAmountPaid();
  }

  private closeTurnstileWithoutReturningMoney(): void {
    this.turnstileService.closeEnter();
    this.machine.toFlowEnd();
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'returningAmountChange':
      case 'returningAmountPaid':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutReturnMoneySec);
      case 'printTickets':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutPrinterSec);
      case 'waitingForTurnstileEnter':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutEntranceSec);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  private getTimeoutSettings(timeoutInSeconds: number): MachineInactivitySettings {
    return new MachineInactivitySettings(timeoutInSeconds * 1000, true);
  }

  onTurnstileEnter(): void {
    if (this.isInState('waitingForTurnstileEnter')) {
      this.machine.toFlowEnd();
    }
  }
}
