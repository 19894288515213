import { Action } from '@ngrx/store';
import { VuState } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new VuState();

export class VuActionType {
  static readonly VU_STATE_MAINTENANCE_MODE = 'MaintenanceMode';
  static readonly VU_STATE_OFFLINE_MODE = 'OfflineMode';
  static readonly VU_STATE_SERVICE_MODE = 'ServiceMode';
  static readonly VU_STATE_BURGLARY_MODE = 'BurglaryMode';
  static readonly VU_STATE_MAINTENANCE_MODE_REASONS = 'MaintenaceModeReasons';
  static readonly VU_STATE_ALL = 'VuStateAll';
}

export function vuReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;

  switch (action.type) {
    case VuActionType.VU_STATE_MAINTENANCE_MODE:
      state.isMaintenanceMode = action.payload;
      break;
    case VuActionType.VU_STATE_OFFLINE_MODE:
      state.isOfflineMode = action.payload;
      break;
    case VuActionType.VU_STATE_SERVICE_MODE:
      state.isServiceMode = action.payload;
      break;
    case VuActionType.VU_STATE_BURGLARY_MODE:
      state.isBurglaryMode = action.payload;
      break;
    case VuActionType.VU_STATE_MAINTENANCE_MODE_REASONS:
      state.maintenaceModeReasons = action.payload;
      break;
    case VuActionType.VU_STATE_ALL:
      let newState = VuState.fromOther(action.payload);
      if (!newState.equals(state)) {
        return newState;
      }

      isChanged = false;
      break;
    default:
      isChanged = false;
      break;
  }

  return isChanged ? VuState.fromOther(state) : state;
}
