import { Injectable, Injector } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { IDisplayGridWorkflowService } from './display-grid-workflow.interface';
import { PrintGridWorkflowService } from './base/print/print-grid-workflow.service';
import { ProductGridWorkflowService } from './payment/product/product-grid-workflow.service';
import { CategoryGridWorkflowService } from './base/category/category-grid-workflow.service';
import { StaticGridWorkflowService } from './base/static/static-grid-workflow.service';
import { TicketGridWorkflowService } from './ticket/ticket/ticket-grid-workflow.service';
import { PinGridWorkflowService } from './pin/pin/pin-grid-workflow.service';
import { OpenFmcuGridWorkflowService } from './fmcu/fmcu-action/fmcu-action-grid-workflow.service';
import { DebugGridWorkflowService } from './base/debug/debug-grid-workflow.service';
import { DisplayItemsComponent } from '../../../components/grid/base/items/items.component';
import { CashPaymentGridWorkflowService } from './payment/cash-payment/cash-payment-grid-workflow.service';
import { StepsListWorkflowService } from './payment/steps-list/steps-list-workflow.service';
import { PaymentConfirmationGridWorkflowService } from './payment/payment-confirmation/payment-confirmation-workflow.service';
import { PaymentCancellationGridWorkflowService } from './payment/payment-cancellation/payment-cancellation-workflow.service';
import { CashRefundGridWorkflowService } from './payment/cash-refund/cash-refund-grid-workflow.service';
import { CashRefundConfirmationGridWorkflowService } from './payment/cash-refund/cash-refund-confirmation/cash-refund-confirmation-workflow.service';
import { RfidCardGridWorkflowService } from './rfid/rfid-card/rfid-card-grid-workflow.service';
import { DisplayGridInactivityService } from './display-grid-inactivity.service';
import { PdfViewerGridWorkflowService } from './viewers/pdf-viewer/pdf-viewer-grid-workflow.service';
import { CIOBoardEventGridWorkflowService } from './cioboard/cioboard-event/cioboard-event-grid-workflow.service';
import { ServerActionGridWorkflowService } from './base/server-action/server-action-grid-workflow.service';
import { BasketGridWorkflowService } from './payment/basket/basket-grid-workflow.service';
import { AssetsService } from '../../assets.service';
import { CardPaymentGridWorkflowService } from './payment/card-payment/card-payment-grid-workflow.service';
import { CreateOrderGridWorkflowService } from './payment/create-order/create-order-grid-workflow.service';
import { KeypadGridWorkflowService } from './text-input/keypad/keypad-grid-workflow.service';
import { InputGridWorkflowService } from './text-input/input/input-grid-workflow.service';
import { MarkdownViewerGridWorkflowService } from './viewers/markdown-viewer/markdown-viewer-grid-workflow.service';
import { ImageViewerGridWorkflowService } from './viewers/image-viewer/image-viewer-grid-workflow.service';
import { CardPaymentAmountInputGridWorkflowService } from './payment/card-payment/card-payment-amount-input-grid-workflow';
import { CardDispenserGridWorkflowService } from './rfid/card-dispenser/card-dispenser-grid-workflow.service';
import { CardDispenserRefundGridWorkflowService } from './rfid/card-dispenser/card-dispenser-refund-grid-workflow.service';
import { CardDispenserCaptureGridWorkflowService } from './rfid/card-dispenser/card-dispenser-capture-grid-workflow.service';
import { CardDispenserReleaseCardGridWorkflowService } from './rfid/card-dispenser/card-dispenser-release-grid-workflow.service';
import { MoneyExchangeGridWorkflowService } from './payment/money_exchange/money-exchange-grid-workflow.service';
import { CardDispenserMonitorGridWorkflowService } from './rfid/card-dispenser/card-dispenser-monitor-grid-workflow.service';

@Injectable()
export class DisplayGridWorkflowService {

  private _context = {};
  private _parentDisplayItem: DisplayItem;
  private _previousDisplayItems: DisplayItem[];
  private readonly _displayGridWorkflowServices: Map<string, IDisplayGridWorkflowService> = new Map<string, IDisplayGridWorkflowService>();

  private _displayGridInactiveService: DisplayGridInactivityService;

  private _gridVisualItemsComponent: DisplayItemsComponent;

  constructor(
    private injector: Injector,
    private assetsService: AssetsService,
    private productGridWorkflowService: ProductGridWorkflowService,
    private debugGridWorkflowService: DebugGridWorkflowService,
    private categoryGridWorkflowService: CategoryGridWorkflowService,
    private staticGridWorkflowService: StaticGridWorkflowService,
    private printGridWorkflowService: PrintGridWorkflowService,
    private ticketGridWorkflowService: TicketGridWorkflowService,
    private rfidCardGridWorkflowService: RfidCardGridWorkflowService,
    private cardDispenserGridWorkflowService: CardDispenserGridWorkflowService,
    private cardDispenserRefundGridWorkflowService: CardDispenserRefundGridWorkflowService,
    private cardDispenserReleaseCardGridWorkflowService: CardDispenserReleaseCardGridWorkflowService,
    private cardDispenserCaptureGridWorkflowService: CardDispenserCaptureGridWorkflowService,
    private cardDispenserMonitorGridWorkflowService: CardDispenserMonitorGridWorkflowService,
    private pinGridWorkflowService: PinGridWorkflowService,
    private openFmcuGridWorkflowService: OpenFmcuGridWorkflowService,
    private cashPaymentGridWorkflowService: CashPaymentGridWorkflowService,
    private stepsListWorkflowService: StepsListWorkflowService,
    private paymentConfirmationGridWorkflowService: PaymentConfirmationGridWorkflowService,
    private paymentCancelationGridWorkflowService: PaymentCancellationGridWorkflowService,
    private cashRefundGridWorkflowService: CashRefundGridWorkflowService,
    private cashRefundConfirmationGridWorkflowService: CashRefundConfirmationGridWorkflowService,
    private pdfViewerGridWorkflowService: PdfViewerGridWorkflowService,
    private cioBoardActionGridWorkflowService: CIOBoardEventGridWorkflowService,
    private serverActionGridWorkflowService: ServerActionGridWorkflowService,
    private basketGridWorkflowService: BasketGridWorkflowService,
    private cardPaymentGridWorkflowService: CardPaymentGridWorkflowService,
    private keypadGridWorkflowService: KeypadGridWorkflowService,
    private inputGridWorkflowService: InputGridWorkflowService,
    private createOrderGridWorkflowService: CreateOrderGridWorkflowService,
    private imageViewerGridWorkflowService: ImageViewerGridWorkflowService,
    private markdownViewerGridWorkflowService: MarkdownViewerGridWorkflowService,
    private cardPaymentAmountInputGridWorkflowService: CardPaymentAmountInputGridWorkflowService,
    private moneyExchangeGridWorkflowService: MoneyExchangeGridWorkflowService,
  ) {
    this._registerService(productGridWorkflowService);
    this._registerService(debugGridWorkflowService);
    this._registerService(categoryGridWorkflowService);
    this._registerService(staticGridWorkflowService);
    this._registerService(printGridWorkflowService);
    this._registerService(ticketGridWorkflowService);
    this._registerService(rfidCardGridWorkflowService);
    this._registerService(cardDispenserGridWorkflowService);
    this._registerService(cardDispenserRefundGridWorkflowService);
    this._registerService(cardDispenserReleaseCardGridWorkflowService);
    this._registerService(cardDispenserCaptureGridWorkflowService);
    this._registerService(cardDispenserMonitorGridWorkflowService);
    this._registerService(pinGridWorkflowService);
    this._registerService(openFmcuGridWorkflowService);
    this._registerService(cashPaymentGridWorkflowService);
    this._registerService(stepsListWorkflowService);
    this._registerService(paymentConfirmationGridWorkflowService);
    this._registerService(paymentCancelationGridWorkflowService);
    this._registerService(cashRefundGridWorkflowService);
    this._registerService(cashRefundConfirmationGridWorkflowService);
    this._registerService(pdfViewerGridWorkflowService);
    this._registerService(cioBoardActionGridWorkflowService);
    this._registerService(serverActionGridWorkflowService);
    this._registerService(basketGridWorkflowService);
    this._registerService(cardPaymentGridWorkflowService);
    this._registerService(keypadGridWorkflowService);
    this._registerService(inputGridWorkflowService);
    this._registerService(createOrderGridWorkflowService);
    this._registerService(imageViewerGridWorkflowService);
    this._registerService(markdownViewerGridWorkflowService);
    this._registerService(cardPaymentAmountInputGridWorkflowService);
    this._registerService(moneyExchangeGridWorkflowService);
  }

  _registerService(displayGridWorkflowService: IDisplayGridWorkflowService) {
    if (!displayGridWorkflowService) {
      return;
    }
    displayGridWorkflowService.initialize(this);
    this._displayGridWorkflowServices.set(displayGridWorkflowService.displayItemType, displayGridWorkflowService);
  }

  initalizeGrid(gridVisualItemsComponent: DisplayItemsComponent) {
    this._gridVisualItemsComponent = gridVisualItemsComponent;
    if (gridVisualItemsComponent == null) {
      this.displayGridInactivityService.disableInactivityTimer();
    }
  }

  reset(): any {
    this.displayGridInactivityService.disableInactivityTimer();
    this._context = {};
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.reset();
      }
    });
  }

  get displayGridInactivityService(): DisplayGridInactivityService {
    if (this._displayGridInactiveService == null) {
      this._displayGridInactiveService = this.injector.get(DisplayGridInactivityService);
    }

    return this._displayGridInactiveService;
  }

  get gridVisualItemsComponent(): DisplayItemsComponent {
    return this._gridVisualItemsComponent;
  }

  get parentDisplayItem(): DisplayItem {
    return this._parentDisplayItem;
  }

  get canStop(): boolean {
    for (const key in this._displayGridWorkflowServices.keys()) {
      if (!this._displayGridWorkflowServices.hasOwnProperty(key)) {
        continue;
      }
      const displayGridWorkflowService = this._displayGridWorkflowServices.get(key);
      if (displayGridWorkflowService && !displayGridWorkflowService.canDoHardReset) {
        return false;
      }
    }

    return true;
  }

  doHardReset(action: () => void) {
    const keys = Array.from(this._displayGridWorkflowServices.keys());
    if (keys.length === 0) {
      return;
    }

    let keyIndex = 0;
    const checkResult = () => {
      keyIndex++;
      if (keys.length <= keyIndex) {
        if (typeof (action) === 'function') {
          action();
        }
      } else {
        this._displayGridWorkflowServices.get(keys[keyIndex]).hardReset(checkResult);
      }
    };

    this._displayGridWorkflowServices.get(keys[keyIndex]).hardReset(checkResult);
  }

  showDisplayItems(parentDisplayItem: DisplayItem, displayItems: DisplayItem[]) {
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.showDisplayItems(this._previousDisplayItems, false, this._context);
      }
    });

    this._parentDisplayItem = parentDisplayItem;
    this._previousDisplayItems = displayItems;
    if (displayItems == null) {
      return;
    }

    this.displayGridInactivityService.enableInactivityTimer();
    this.displayGridInactivityService.updateInactivityTimerIfEnabled();

    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.showDisplayItems(displayItems, true, this._context);
      }
    });
  }

  clickDisplayItems(displayItem: DisplayItem) {
    if (displayItem == null) {
      return false;
    }

    let result = false;
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService != null) {
        if (displayGridWorkflowService.processAction(displayItem, this._context)) {
          result = true;
        }
      }
    });
    return result;
  }

  isSupportDisplayItem(displayItem: DisplayItem): boolean {
    if (displayItem == null) {
      return false;
    }
    return this._displayGridWorkflowServices.has(displayItem.type);
  }

  updateContext(context: any) {
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.updateContext(context);
      }
    });

    this._context = Object.assign(this._context, context);
  }

  buildExternalApiRequestData(): Map<string, any> {
    const requestData = new Map<string, any>();
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.fillExternalApiRequestData(requestData, this._context);
      }
    });

    return requestData;
  }

  private getDisplayItemRenderData(displayItem: DisplayItem): Map<string, any> {
    const data: Map<string, any> = new Map<string, any>();
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.fillDisplayItemRenderData(displayItem, data, this._context);
      }
    });

    return data;
  }

  getformattedHtmlTemplate(displayItem: DisplayItem, language: string): string {
    const renderData = this.getDisplayItemRenderData(displayItem);
    const formattedHtmlTemplate = displayItem.getformattedHtmlTemplate(language, renderData);
    return this.assetsService.replaceAssetsUrls(formattedHtmlTemplate, language);
  }

  changeIsDisabledState(uniqueName: string, isDisabled: boolean) {
    if (uniqueName == null || uniqueName.trim().length === 0 ||
      this._previousDisplayItems == null) {
      return;
    }

    const previousDisplayItem = this._previousDisplayItems.find((p) => p.uniqueName === uniqueName);
    if (previousDisplayItem != null) {
      previousDisplayItem.isDisabled = isDisabled;
    }
  }

  getContextParameter(parameter: string): any {
    if (parameter && this._context && this._context[parameter]) {
      return this._context[parameter];
    }
    return false;
  }
}
