import { Injectable, Injector } from '@angular/core';
import { ConfigurationService } from '../../configuration/configuration.service';
import { TimeoutModalComponent } from '../../../components/general/timeout-modal/timeout-modal.component';
import { ModalService } from '../../gui/modal/modal-service';
import { LoggingService } from '../../logging/logging.service';
import { DisplayGridWorkflowService } from './display-grid-workflow.service';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { StoreHistoryService } from '../../store-history.service';
import { InactivityTimerBaseService } from '../../inactivity-timer/inactivity-timer-base.service';

@Injectable()
export class DisplayGridInactivityService extends InactivityTimerBaseService {
  private internalDisplayGridWorkflowService: DisplayGridWorkflowService;

  constructor(
    protected configurationService: ConfigurationService,
    protected modalService: ModalService,
    protected loggingService: LoggingService,
    private storeHistoryService: StoreHistoryService,
    private injector: Injector,
  ) {
    super(configurationService, modalService, loggingService);
  }

  get displayGridWorkflowService(): DisplayGridWorkflowService {
    if (this.internalDisplayGridWorkflowService == null) {
      this.internalDisplayGridWorkflowService = this.injector.get(DisplayGridWorkflowService);
    }

    return this.internalDisplayGridWorkflowService;
  }

  get parentDisplayItem(): DisplayItem {
    return this.displayGridWorkflowService.parentDisplayItem;
  }

  protected get allowRestartTimer(): boolean {
    return !this.storeHistoryService.isEmpty;
  }

  protected get inactivityTimeoutWithoutModal(): number {
    if (this.parentDisplayItem && this.parentDisplayItem.timeoutToShowNextItem > 0 && this.parentDisplayItem.navigationItem) {
      return this.parentDisplayItem.timeoutToShowNextItem;
    }
    return 0;
  }

  protected tryStopAllWorkflow(): void {
    if (!this.displayGridWorkflowService.canStop) {
      this._restartTimer();
      return;
    }

    this.displayGridWorkflowService.doHardReset(() => {
      if (this.parentDisplayItem && this.inactivityTimeoutWithoutModal) {
        this.displayGridWorkflowService.gridVisualItemsComponent.showAdditionDisplayItem(this.parentDisplayItem.navigationItem);
      } else {
        this.displayGridWorkflowService.gridVisualItemsComponent.navigateToRootPage();
      }
      this.displayGridWorkflowService.reset();
    });
  }
}
