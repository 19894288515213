import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration/configuration.service';
import { ExternalPaymentService } from '../modules/external-payment/services/external-payment.service';
import { LoggingService } from './logging/logging.service';
import { ExternalTicketService } from '../modules/external-ticket/services/external-ticket.service';
import { NonOperationalService } from './non-operational.service';

@Injectable()
export class ShopRedirectService {
  constructor(
    private configurationService: ConfigurationService,
    private externalPaymentService: ExternalPaymentService,
    private externalTicketService: ExternalTicketService,
    private loggingService: LoggingService,
    private nonOperationalService: NonOperationalService,
  ) {
  }

  tryRedirectToShop(): boolean {
    if (!this.shopUrl || this.isOutOfOrder) {
      return false;
    }

    this.redirectToExternalUrl(this.fullShopUrl);
    return true;
  }

  get isOutOfOrder(): boolean {
    return this.nonOperationalService.state.isNonOperational;
  }

  tryRedirectToExternalBackUrl(paymentCancelled: boolean): boolean {
    if (!this.shopUrl || this.isOutOfOrder) {
      return false;
    }

    if (paymentCancelled && this.externalPaymentService.cancelUrl) {
      this.redirectToExternalUrl(this.externalPaymentService.cancelUrl);
      return true;
    }

    if (this.externalPaymentService.returnUrl) {
      this.redirectToExternalUrl(this.externalPaymentService.returnUrl);
      return true;
    }

    return this.tryRedirectToShop();
  }

  private redirectToExternalUrl(url: string): void {
    if (!url) {
      return;
    }

    this.externalTicketService.deactivate();

    this.loggingService.info(`ShopRedirectService. Redirect to url: ${url}`);
    window.location.href = url;
  }

  get shopUrl(): string {
    if (
      this.configurationService
      && this.configurationService.configuration
      && (
        this.configurationService.configuration.shopType === 'PosOrderWebshop'
        ||
        this.configurationService.configuration.shopType === 'SaleOrderWebshop')
    ) {
      return this.configurationService.configuration.shopUrl;
    }

    return '';
  }

  get fullShopUrl(): string {
    const shopUrl = this.shopUrl;

    if (
      !this.configurationService ||
      !this.configurationService.configuration ||
      !this.configurationService.configuration.odooUrl ||
      !shopUrl) {
      return '';
    }

    if (shopUrl.includes('https://v21.maxcrc.de')) {
      return shopUrl.replace('https://v21.maxcrc.de', '');
    }

    const odooUrl = this.configurationService.configuration.odooUrl;
    if (odooUrl.includes('simulation://')) {
      return '/store/external-payment?orderId=2&url=%2Fexternal-payment%2F&paymentType=cash';
    }
    return odooUrl + shopUrl;
  }
}
