import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { RfidCardReaderService } from '../../../../rfid-card/rfid-card-reader.service';
import { Subscription } from 'rxjs';

@Injectable()
export class RfidCardGridWorkflowService extends BaseGridWorkflowService {

  subscription: Subscription;
  private rfidCardReaderService: RfidCardReaderService;

  init(
  ) {
    super.init();
    this.rfidCardReaderService = this.injector.get(RfidCardReaderService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.RfidCard;
  }

  show(displayItem: DisplayItem, context: any): void {
    super.show(displayItem, context);
    this.enableRfidCardScanned();
  }

  hide(displayItem: DisplayItem, context: any): void {
    super.hide(displayItem, context);
    this.disabledRfidCardScanned();
  }

  hideAll(): void {
    super.hideAll();
    this.disabledRfidCardScanned();
  }

  get isEnableRfidCardScanned(): boolean {
    return this.subscription != null;
  }

  enableRfidCardScanned() {
    if (this.isEnableRfidCardScanned) {
      return;
    }
    this.subscription = this.rfidCardReaderService.rfidCardScanned.subscribe((x: string) => this.onRfidCardScanned(x));
    this.rfidCardLedEnable(true);
  }

  disabledRfidCardScanned() {
    if (!this.isEnableRfidCardScanned) {
      return;
    }
    this.rfidCardLedEnable(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  private rfidCardLedEnable(value: boolean) {
    if (this.dispatcherService.vuHttp != null) {
      this.dispatcherService.vuHttp.rfidCardLedEnable(value);
    }
  }

  onRfidCardScanned(cardCode: string) {
    this.userActivity();
    if (!this.context) {
      return;
    }
    this.displayGridWorkflowService.updateContext({
      rfidCardCode: cardCode
    });

    this.navigateToUrlOrNavigationItem(this.displayItem);
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
    if (context.rfidCardCode) {
      requestData.set('barcode', context.rfidCardCode);
    }
  }
}
