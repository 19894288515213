import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoggingService } from '../../logging/logging.service';

@Injectable()
export class ModalService {

  private modals: Set<BsModalRef> = new Set<BsModalRef>();

  constructor(
    private modalService: BsModalService,
    private loggingService: LoggingService,
  ) {
  }

  public show(baseModalComponent: any, data: any, complete: (data: any) => void): BsModalRef {
    let modalRef = this.modalService.show(
      baseModalComponent,
      {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      });

    this.modals.add(modalRef);

    this.logModalChangeState(modalRef, 'show');
    const content = modalRef.content as any;

    content.data = data;

    const eventCloseSubscription = content.eventClose.subscribe(
      (eventData) => {
        this.logModalChangeState(modalRef, 'eventClose');
        eventCloseSubscription.unsubscribe();
        if (modalRef) {
          this.modals.delete(modalRef);
          modalRef.hide();
          modalRef = null;
        }
        if (complete) {
          complete(eventData);
        }
      });

    return modalRef;
  }

  public close(modalRef: BsModalRef, params = null): void {
    if (modalRef && modalRef.content) {
      setTimeout(() => {
        this.logModalChangeState(modalRef, 'close');
        modalRef.content.close(params);
      }, 0);
    }
  }

  public closeAll(): void {
    if (this.modals.size === 0) {
      return;
    }

    this.loggingService.info(`ModalService. closeAll. Modal count: ${this.modals.size}`);

    for (const modal of this.modals) {
      this.close(modal);
    }

    this.modals.clear();
  }

  private logModalChangeState(modalRef: BsModalRef, state: string): void {
    if (!modalRef || !modalRef.content || !modalRef.content.constructor || !modalRef.content.constructor.name) {
      return;
    }

    this.loggingService.info(`ModalService. ${state}. Modal name: ${modalRef.content.constructor.name}`);
  }
}
