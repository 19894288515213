import { Injectable, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MachineInactivity } from '../../lib/lib';
import { LoggingService } from '../logging/logging.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { DispatcherService } from '../dispatcher.service';
import { TimeoutModalComponent } from '../../components/general/timeout-modal/timeout-modal.component';
import { ModalService } from '../gui/modal/modal-service';

@Injectable()
export class MachinesInactivityService {
  private timer: any = null;
  private modalRef: BsModalRef;
  private internalMachineInactivity: MachineInactivity;
  private internalLog: LoggingService;

  constructor(
    private injector: Injector,
    private modalService: ModalService,
    private configService: ConfigurationService,
    private dispatcherService: DispatcherService
  ) {
    this.dispatcherService.eventMachineInactivityChangeTracking.subscribe((x: MachineInactivity) => this.changeTracking(x));
    this.dispatcherService.eventUserActivity.subscribe(() => this.onUserActivity());
  }

  get machineInactivity(): MachineInactivity {
    return this.internalMachineInactivity;
  }

  set machineInactivity(x: MachineInactivity) {
    this.internalMachineInactivity = x;
  }

  get log() {
    if (this.internalLog == null) {
      this.internalLog = this.injector.get(LoggingService);
    }
    return this.internalLog;
  }

  simulateTimeoutModalOpen() {
    this.timeoutModalOpen();
  }

  private timeoutModalOpen(timeout = 15) {
    if (this.modalRef) {
      return;
    }

    this.modalRef = this.modalService.show(
      TimeoutModalComponent,
      null,
      (x: boolean) => {
        this.modalRef = null;
        this.resetInactivity(x, true);
      }
    );

    this.modalRef.content.startTimer(timeout);
    this.log.info(`MachinesInactivityService. timeoutModalOpen. timeout: ${timeout}. ${this.machineInactivity}`);
  }

  private timeoutModalClose(isContinue: boolean, isSendEvent: boolean) {
    this.modalService.close(this.modalRef, isContinue);
    this.resetInactivity(isContinue, isSendEvent);
  }

  private startTimer() {
    this.resetTimer();
    const customTimeout = this.machineInactivity != null ? this.machineInactivity.customTimeout : -1;
    const timeout = customTimeout < 0 ? this.configService.configuration.timeIntervalBeforeTimeoutModal : customTimeout;
    this.timer = setTimeout(() => {
      const skipToOpenTimeoutModal = this.machineInactivity != null ? this.machineInactivity.skipToOpenTimeoutModal : false;
      if (skipToOpenTimeoutModal) {
        this.resetInactivity(false, true);
      } else {
        this.timeoutModalOpen();
      }
    }, timeout);
    this.log.info(`Inactivity timer set for '${timeout}'`);
  }

  private resetTimer() {
    if (this.timer) {
      this.log.info(`Inactivity timer reset`);
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private changeTracking(machineInactivity: MachineInactivity) {
    const currentMachineName = this.machineInactivity != null ? this.machineInactivity.machineName : null;
    const isDifferentMachineNames = currentMachineName !== null && currentMachineName !== machineInactivity.machineName;
    const isDifferentMachineStates = currentMachineName != null && this.machineInactivity.machineState !== machineInactivity.machineState;

    if (isDifferentMachineNames || isDifferentMachineStates || !machineInactivity.isStartTracking) {
      this.timeoutModalClose(false, false);
    }

    this.machineInactivity = machineInactivity;

    if (machineInactivity.isStartTracking) {
      this.timeoutModalClose(true, false);
    }
  }

  private resetInactivity(isContinue: boolean, isSendEvent: boolean) {
    const m = `MachinesInactivityService. resetInactivity.`
      + ` isContinue: ${isContinue}. isSendEvent: ${isSendEvent}. ${this.machineInactivity}`;
    this.log.info(m);
    if (isContinue) {
      this.startTimer();
      return;
    }

    this.resetTimer();
    const machineName = this.machineInactivity ? this.machineInactivity.machineName : null;
    if (isSendEvent) {
      setTimeout(() => this.dispatcherService.machineTimeoutModalCancel(machineName), 0);
    }
    this.machineInactivity = null;
  }

  private onUserActivity() {
    if (this.machineInactivity != null) {
      this.changeTracking(this.machineInactivity);
    }
  }
}
