import { Injectable } from '@angular/core';
import { MachineInactivitySettings, WorkflowStepType } from '../../lib/lib';
import { ExternalCardRechargeModel } from '../../modules/external-payment/models/external-card-recharge-model';
import { ExternalPaymentService } from '../../modules/external-payment/services/external-payment.service';
import { Message, MessageType } from '../message.service';
import { UnrestrictedCashPaymentService } from '../payment/unrestricted-cash-payment.service';
import { SaleService } from '../sale.service';
import { ShopRedirectService } from '../shop-redirect.service';
import { MachineBaseService } from './machine-base.service';
import { MachinePostSaleService } from './machine-post-sale.service';

@Injectable()
export class MachineCardRechargeService extends MachineBaseService {

  private unrestrictedCashPaymentService: UnrestrictedCashPaymentService;
  private machinePostSaleService: MachinePostSaleService;
  private shopRedirectService: ShopRedirectService;
  private externalPaymentService: ExternalPaymentService;
  public externalCardRechargeModel: ExternalCardRechargeModel;
  private saleService: SaleService;
  private waitingForPaymentDevicesDisableTimeout = 2000;

  init(): void {
    super.init();
    this.unrestrictedCashPaymentService = this.injector.get(UnrestrictedCashPaymentService);
    this.machinePostSaleService = this.injector.get(MachinePostSaleService);
    this.machinePostSaleService.eventSwitchedOff.subscribe(() => this.onMachinePostSaleSwitchedOff());
    this.externalPaymentService = this.injector.get(ExternalPaymentService);
    this.shopRedirectService = this.injector.get(ShopRedirectService);
    this.saleService = this.injector.get(SaleService);
  }

  get machineName(): string {
    return 'Card Recharge Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toAcceptingCashInfo', from: ['off', 'payout'], to: 'acceptingCashInfo' },
      { name: 'toWaitingForPaymentDevicesDisable', from: ['acceptingCashInfo'], to: 'waitingForPaymentDevicesDisable' },
      { name: 'toPostSale', from: ['waitingForPaymentDevicesDisable'], to: 'postSale' },
      { name: 'toFlowEnd', from: ['acceptingCashInfo', 'postSale'], to: 'flowEnd' },
    );
  }

  protected getMethods(): any {
    return super.getMethods({
      onToAcceptingCashInfo: () => {
        this.unrestrictedCashPaymentService.beginTransaction(this.externalCardRechargeModel).subscribe(x => {
          this.router.navigateByUrl('/card-recharge');
        });
      },
      onToWaitingForPaymentDevicesDisable: () => {
        this.dispatcherService.isBackButtonEnabled = false;
        this.dispatcherService.workflowReset('Completing Process', WorkflowStepType.None);
        this.dispatcherService.teaserReset();
        this.router.navigateByUrl('/workflow');
        this.dispatcherService.workflowAddStep(WorkflowStepType.Wait);

        const toPostSaleAction = () =>
          this.doAsync(() => this.machine.toPostSale(), 'onToWaitingForPaymentDevicesDisable');

        this.unrestrictedCashPaymentService.commitTransaction().subscribe(x => {
          setTimeout(() => {
              toPostSaleAction();
          }, this.waitingForPaymentDevicesDisableTimeout);
        });
      },
      onToPostSale: () => {
        this.dispatcherService.isBackButtonEnabled = false;
        this.machinePostSaleService.externalPayment = this.externalPaymentService.isEnabled;
        this.doAsync(() => this.machinePostSaleService.machineStart(), 'onToPostSale');
      },
      onToFlowEnd: () => {
        this.dispatcherService.isBackButtonEnabled = true;
      },
      onToOff: (event: any, isHardReset: false) => {
        this.dispatcherService.workflowReset('', WorkflowStepType.None);
      },
    });
  }

  machineStart(withoutTransition = false): void {
    this.machine.toAcceptingCashInfo();
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
      MessageType.ConfirmPayment,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (this.state === 'acceptingCashInfo') {
          this.commitTransactionAndRedirect();
        }
        break;
      case MessageType.ConfirmPayment:
        this.machine.toWaitingForPaymentDevicesDisable();
        break;
      default:
        break;
    }
    return false;
  }

  protected get timeoutTrackingStates(): string[] {
    return ['acceptingCashInfo', 'waitingForPaymentDevicesDisable', 'flowEnd'];
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (
      machineName === this.machineName &&
      this.isInState('acceptingCashInfo')
    ) {
      const order = this.saleService.order;
      if (
        order &&
        order.paymentSession.isSessionOpen &&
        order.paymentSession.amountPaidIn.isPositive
      ) {
        this.machine.toWaitingForPaymentDevicesDisable();
        return;
      } else {
        this.commitTransactionAndRedirect();
        return;
      }
    }
    super.onMachineTimeoutModalCancel(machineName);
  }

  commitTransactionAndRedirect() {
    this.unrestrictedCashPaymentService.commitTransaction().subscribe(x => {
      if (this.shopRedirectService.tryRedirectToExternalBackUrl(true)) {
        return;
      }
      this.machine.toOff();
    });
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'flowEnd':
        return new MachineInactivitySettings(10000, true);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  onMachinePostSaleSwitchedOff(): void {
    if (this.state !== 'postSale') {
      return;
    }

    this.shopRedirectService.tryRedirectToExternalBackUrl(false);
  }
}
