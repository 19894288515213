import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TimeoutModalComponent } from 'src/app/components/general/timeout-modal/timeout-modal.component';
import { ConfigurationService } from '../configuration/configuration.service';
import { ModalService } from '../gui/modal/modal-service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class InactivityTimerBaseService {
  private timer: any;

  private modalRef: BsModalRef;

  private inactivityTimerEnabledState = false;

  constructor(
    protected configurationService: ConfigurationService,
    protected modalService: ModalService,
    protected loggingService: LoggingService,
  ) {
  }

  get inactivityTimerEnabled(): boolean {
    return this.inactivityTimerEnabledState;
  }

  enableInactivityTimer(): void {
    if (this.inactivityTimerEnabledState) {
      return;
    }
    this.inactivityTimerEnabledState = true;
    this._restartTimer();
  }

  disableInactivityTimer(): void {
    if (!this.inactivityTimerEnabledState) {
      return;
    }
    this.inactivityTimerEnabledState = false;
    this._stopTimer();
  }

  updateInactivityTimerIfEnabled(): void {
    if (!this.inactivityTimerEnabledState) {
      return;
    }
    this._restartTimer();
  }

  protected _restartTimer(): void {

    this._stopTimer();

    if (!this.inactivityTimerEnabledState) {
      return;
    }

    if (!this.allowRestartTimer) {
      return;
    }

    const timeout = this.inactivityTimeoutWithoutModal || this.configurationService.configuration.timeIntervalBeforeTimeoutModal;
    this._startInactivityTimer(timeout);
  }

  protected get allowRestartTimer(): boolean {
    return true;
  }

  protected get inactivityTimeoutWithoutModal(): number {
    return 0;
  }

  private _startInactivityTimer(timeout: number): void {
    this.loggingService.info(`InactivityTimerBaseService. startInactivityTimer, ${timeout} ms`);
    this.timer = setTimeout(() => {
      this._inactivityTimeout();
    }, timeout);
  }

  private _stopTimer(): void {
    if (this.timer) {
      this.loggingService.info('InactivityTimerBaseService. stopTimer');
      clearTimeout(this.timer);
    }
    this.timer = null;
    if (this.modalRef) {
      this.modalService.close(this.modalRef);
      this.modalRef = null;
    }
  }

  private _inactivityTimeout(): void {
    this._showInactivityDialog(
      (cancel) => {
        if (cancel) {
          this._restartTimer();
        } else {
          this.tryStopAllWorkflow();
        }
      }
    );
  }

  private _showInactivityDialog(action: (cancel: boolean) => void): void {
    if (this.inactivityTimeoutWithoutModal) {
      if (action) {
        action(false);
      }
    } else {
      this.modalRef = this.modalService.show(
        TimeoutModalComponent,
        null,
        (isContinue: boolean) => {
          if (!this.modalRef) {
            return;
          }

          if (isContinue) {
            this._restartTimer();
          } else {
            if (action) {
              action(false);
            }
          }
        }
      );

      this.modalRef.content.startTimer(15);
    }
  }

  protected tryStopAllWorkflow(): void {
  }
}
