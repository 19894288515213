import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LanguageService } from '../../../../services/language.service';
import { Locale } from '../../../../lib/lib';
import { BaseModalComponent } from '../../base/base-modal-component';

@Component({
  moduleId: module.id,
  selector: 'language-selection-popup',
  templateUrl: './language-selection-popup.component.html',
  styleUrls: ['./language-selection-popup.component.scss'],
})
export class LanguageSelectionPopUpComponent extends BaseModalComponent {

  flags = {
    'de-DE': '/static/images/flags/de-DE.png',
    'en-US': '/static/images/flags/en-US.png',
    'uk-UA': '/static/images/flags/uk-UA.png',
    'cs-CZ': '/static/images/flags/cs-CZ.png',
    'pl-PL': '/static/images/flags/pl-PL.png',
    'fr-FR': '/static/images/flags/fr-FR.png',
    'ro-RO': '/static/images/flags/ro-RO.png',
  };

  constructor(
    protected languageService: LanguageService
  ) {
    super();
  }

  get langs(): Locale[] {
    return this.languageService.avalibleLocalesForCustomer;
  }

  onClick(id: any) {
    this.close(id);
  }
}
