import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DispatcherService } from '../../services/dispatcher.service';
import { MoneyExchangeStateService } from '../../services/money-exchange/money-exchange-state.service';

@Component({
  selector: 'root',
  templateUrl: './root.component.html',
  styleUrls: ['root.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RootComponent implements OnInit, OnDestroy {
  backButtonVisible: boolean;
  abortButtonVisible: boolean;

  constructor(
    private dispatcherService: DispatcherService,
    private moneyExchangeStateService: MoneyExchangeStateService,
  ) {
   }

  onSaleButtonClick() {
    this.dispatcherService.onButtonSaleShopClick();
  }

  onInfoButtonClick() {
    this.dispatcherService.onButtonInfoClick();
  }

  onTicketButtonClick() {
    this.dispatcherService.onButtonTicketClick();
  }

  get isMoneyExchangeVisibleButton(): boolean {
    return this.moneyExchangeStateService.isEnabled;
  }

  onMoneyExchangeButtonClick() {
    this.dispatcherService.onButtonMoneyExchangeClick();
  }

  ngOnInit() {
    this.dispatcherService.pageHeaderSet('Please select an item');
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;
    this.abortButtonVisible = this.dispatcherService.isAbortButtonVisible;
    this.dispatcherService.isAbortButtonVisible = false;
  }
  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.dispatcherService.isAbortButtonVisible = this.abortButtonVisible;
  }
}

