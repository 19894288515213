import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Workflow } from '../../../lib/lib';
import { LoggingService } from '../../../services/logging/logging.service';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  moduleId: module.id,
  selector: 'workflow',
  templateUrl: 'workflow.component.html',
  styleUrls: ['workflow.component.scss']
})
export class WorkflowComponent implements OnInit, OnDestroy {
  workflow: Workflow;
  workflowSubscription: Subscription;

  constructor(
    private log: LoggingService,
    private dispatcherService: DispatcherService,
  ) {
  }

  ngOnInit(): void {
    this.workflowSubscription = this.dispatcherService.onWorkflowStateChangedSubscribe(x => this.onWorkflowStateChanged(x));
    this.dispatcherService.pageHeaderSet(this.workflow ? this.workflow.name : 'Workflow');
    this.dispatcherService.backButtonTextSet('Home');
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.backButtonTextReset();
    this.workflowSubscription.unsubscribe();
  }

  onWorkflowStateChanged(workflow: Workflow) {
    this.workflow = workflow;
  }
}
