import { Component, Input, Injector, OnInit, OnDestroy } from '@angular/core';
import { LoggingService } from '../../../services/logging/logging.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { Subscription, Observable, of } from 'rxjs';
import { Product } from '../../../lib/lib';
import { DispatcherService } from '../../../services/dispatcher.service';
import { BaseModalComponent } from '../../general/base/base-modal-component';


@Component({
  selector: 'app-dav-scan-card-modal',
  templateUrl: './base-scan-card-modal.component.html',
  styleUrls: ['./base-scan-card-modal.component.css']
})

export class BaseScanCardModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  protected log: LoggingService;

  scanResult: boolean = undefined;
  scanResultMessage = '';
  cssClass = '';
  state = 'normal';

  private _eventBarcodeReadSubscription: Subscription;
  private _barcodeValidationSubscription: Subscription;

  private vuCommunicationService: VuCommunicationService;
  private dispatcherService: DispatcherService;
  private timer: any;
  private timerTimeout = 5000;

  constructor(
    protected injector: Injector,
  ) {
    super();
    this.log = this.injector.get(LoggingService);
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.dispatcherService = this.injector.get(DispatcherService);
  }

  ngOnInit(): void {
    this._eventBarcodeReadSubscription = this.vuCommunicationService.vuConnection.eventBarcodeRead
      .subscribe((x: string) => this.onBarcodeRead(x));
    this.barcodeLedEnable(true);
  }

  ngOnDestroy(): void {
    if (this._eventBarcodeReadSubscription) {
      this._eventBarcodeReadSubscription.unsubscribe();
      this._eventBarcodeReadSubscription = null;
    }

    this._unsibscribeBarcodeValidationSubscription();

    this.barcodeLedEnable(false);

    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private barcodeLedEnable(value: boolean): void {
    this.vuCommunicationService.vuHttp.barcodeLedEnable(value);
  }

  get title(): string {
    if (this.internalData.title) {
      return this.internalData.title;
    }

    return 'Person';
  }

  set title(value: string) {
    this.internalData.title = value;
  }

  get message(): string {
    if (this.internalData.message) {
      return this.internalData.message;
    }

    return 'Message';
  }

  set message(value: string) {
    this.internalData.message = value;
  }

  get product(): Product {
    if (this.internalData.product) {
      return this.internalData.product;
    }

    return null;
  }

  set product(value: Product) {
    this.internalData.product = value;
  }

  get cards(): string[] {
    if (!this.internalData.cards) {
      this.internalData.cards = new Array<string>();
    }
    return this.internalData.cards;
  }

  set cards(cards: string[]) {
    this.internalData.cards = cards;
  }

  get ignoreCards(): string[] {
    if (!this.internalData.ignoreCards) {
      this.internalData.ignoreCards = new Array<string>();
    }
    return this.internalData.ignoreCards;
  }

  set ignoreCards(ignoreCards: string[]) {
    this.internalData.ignoreCards = ignoreCards;
  }

  get personNumber(): number {
    if (!this.cards || !this.groupSize) {
      return 1;
    }

    const personNumber = this.cards.length + 1;
    if (this.groupSize < personNumber) {
      return this.groupSize;
    }

    return personNumber;
  }

  get groupSize(): number {
    return this.internalData.groupSize;
  }

  set groupSize(groupSize: number) {
    this.internalData.groupSize = groupSize;
    if (groupSize !== 0) {
      this.updateModalWindow();
    }
  }

  get isShowMessage(): boolean {
    return this.state === 'normal';
  }

  onBarcodeRead(barcode: string): void {
    this.dispatcherService.onUserActivity();
    this.barcodeProcessing(barcode);
  }

  close(isContinue: any): void {
    super.close(
      {
        isContinue,
        cards: isContinue ? this.cards : []
      });
  }

  barcodeValidation(barcode: string): Observable<boolean> {
    if (this.cards.length !== 0 && this.cards.indexOf(barcode) !== -1) {
      this.showMessage(this.cardAlreadyScannedMessage, false);
      this.log.info(this.scanResultMessage + ` Barcode: '${barcode}'`);
      return of(false);
    }
    return of(true);
  }

  barcodeProcessing(barcode: string): void {

    this._unsibscribeBarcodeValidationSubscription();
    this.barcodeValidation(barcode).subscribe(
      (result) => {
        if (!result) {
          return;
        }

        if (!this.cards || this.cards.indexOf(barcode) !== -1) {
          return;
        }

        this.cards.push(barcode);
        this.showMessage(this.cardSuccessfullyScannedMessage, true);
        this.log.info(this.scanResultMessage);

        if (!this.groupSize || this.cards.length === this.groupSize) {
          this.close(true);
        }
      }
    );
  }

  private _unsibscribeBarcodeValidationSubscription() {
    if (this._barcodeValidationSubscription) {
      this._barcodeValidationSubscription.unsubscribe();
      this._barcodeValidationSubscription = null;
    }
  }

  get cardAlreadyScannedMessage(): string {
    return 'This card is already scanned. Please scan another.';
  }

  get cardSuccessfullyScannedMessage(): string {
    return 'The DAV card was successfully scanned.';
  }

  updateModalWindow(): void {
    if (this.scanResult !== undefined) {
      this.state = this.scanResult ? 'successful' : 'error';
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      if (this.state !== 'normal') {
        this.state = 'normal';
      }
    }, this.timerTimeout);
  }

  showMessage(message: string, scanSuccess: boolean): void {
    this.scanResultMessage = message;
    this.scanResult = scanSuccess;
    this.updateModalWindow();
  }
}
