export class CreditCardMessageStore {
  message: string;
  higlightMessage: boolean;
  terminalText: string;

  constructor() {
    this.reset();
  }

  reset() {
    this.message = null;
    this.terminalText = null;
    this.higlightMessage = false;
  }
}
