import { NgModule, APP_INITIALIZER, LOCALE_ID, InjectionToken } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AppComponent } from './app.component';
import { SignalRModule } from 'ng2-signalr';
import { SignalRConfiguration } from 'ng2-signalr';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './components/root/root.component';
import { PaymentCashComponent } from './components/shop/payment-cash/payment-cash.component';
import { PaymentCardComponent } from './components/shop/payment-card/payment-card.component';
import { WorkflowComponent } from './components/workflow/workflow/workflow.component';
import { ConfigurationService } from './services/configuration/configuration.service';
import reducers from './reducers/reducers';
import { TimeoutModalComponent } from './components/general/timeout-modal/timeout-modal.component';
import { TicketInfoTwoSidedComponent } from './components/ticket/ticket-info-two-sided/ticket-info-two-sided.component';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { GlobalSettings } from './lib/lib';
import { BackButtonComponent } from './components/general/back-button/back-button.component';
import { DummyComponent } from './components/general/dummy/dummy.component';
import { PageHeaderComponent } from './components/general/page-header/page-header.component';
import { PageHeaderTitleComponent } from './components/general/page-header-title/page-header-title.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { TicketParametersComponent } from './components/general/ticket-parameters/ticket-parameters.component';
import { PageMessageComponent } from './components/general/page-message/page-message.component';
import { NumberSpinnerComponent } from './components/general/number-spinner/number-spinner.component';
import { DatapickerRangeComponent } from './components/datapicker-range/datapicker-range.component';
import { SelectComponent } from './components/select/select.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { DatapickerComponent } from './components/datepicker/datepicker.component';
import { NotificationForEntranceComponent } from './components/general/notification-for-entrance/notification-for-entrance.component';
import { PrintModalComponent } from './components/print-modal/print-modal.component';
import { BaseScanCardModalComponent } from './components/modal/base-scan-card-modal/base-scan-card-modal.component';
import { InfoModalComponent } from './components/modal/info-modal/info-modal.component';
import { NumberValidator } from './directives/number-validator.directive';
import { NotificationMessageComponent } from './components/general/notification-message/notification-message.component';
import { AbortButtonComponent } from './components/general/abort-button/abort-button.component';
import { BasketButtonComponent } from './components/general/basket-button/basket-button.component';
import { SharedModule } from './modules/shared/shared.module';
import { LoadingSpinnerModule } from './modules/loading-spinner/loading-spinner.module';
import { VUTranslateModule, HttpLoaderFactory } from './modules/vu-translate/vu-translate.module';
import { ComponentsModule } from './modules/components/components.module';
import { PaymentCashModule } from './modules/payment-cash/payment-cash.module';
import { ReceiptModule } from './modules/receipt/receipt.module';
import { PaymentCardModule } from './modules/payment-card/payment-card.module';
import { WorkflowModule } from './modules/workflow/workflow.module';
import { SimulatorModule } from './modules/simulator/simulator.module';
import { LanguagesModule } from './modules/languages/Languages.module';
import { PingStateService } from './services/ping-state/ping-state.service';
import { ExternalPaymentModule } from './modules/external-payment/external-payment.module';
import { ExternalPaymentComponent } from './modules/external-payment/components/external-payment/external-payment.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ExternalTicketModule } from './modules/external-ticket/external-ticket.module';
import { ExternalTicketComponent } from './modules/external-ticket/components/external-ticket/external-ticket.component';
import { MessageModalComponent } from './components/modal/message-modal/message-modal.component';
import { BootstrapService } from './services/bootstrap.service';
import { ExternalUseBaseComponent } from './modules/external-base/components/external-use-base/external-use-base.component';
import { LanguageService } from './services/language.service';
import { RFIDCardModule } from './modules/rfid-card/rfid-card.module';
import { BarcodeModule } from './modules/barcode/barcode.module';

export function createConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  c.hubName = 'indexHub';
  //  c.qs = { user: 'donald' };
  c.url = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  //  c.logging = true;
  return c;
}

export function init(configurationService: ConfigurationService) {
  return () => configurationService.load();
}

// export function init(bootstrapService: BootstrapService) {
//   return () => bootstrapService.initialize();
// }

const routes: Routes = [
  { path: 'root', component: RootComponent },
  { path: '', redirectTo: '/root', pathMatch: 'full' },
  { path: 'visual-items', loadChildren: () => import('./modules/visual-items/visual-items.module').then(m => m.VisualItemsModule) },
  { path: 'message', component: PageMessageComponent },
  { path: 'ticket', loadChildren: () => import('./modules/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'basket', loadChildren: () => import('./modules/basket/basket.module').then(m => m.BasketModule) },
  { path: 'payment-cash', component: PaymentCashComponent },
  { path: 'payment-card', component: PaymentCardComponent },
  { path: 'workflow', component: WorkflowComponent },
  { path: 'screen-saver', loadChildren: () => import('./modules/screen-saver/screen-saver.module').then(m => m.ScreenSaverModule) },
  { path: 'lite-mode', loadChildren: () => import('./modules/lite-mode/lite-mode.module').then(m => m.LiteModeModule) },
  { path: 'grid-visual-items', loadChildren: () => import('./modules/grid/grid.module').then(m => m.GridModule) },
  { path: 'external-payment', component: ExternalPaymentComponent },
  { path: 'external-ticket', component: ExternalTicketComponent },
  { path: 'external-use/:name', component: ExternalUseBaseComponent },
  { path: 'recharge-card', loadChildren: () => import('./modules/recharge-card/recharge-card.module').then(m => m.RechargeCardModule) },
];

export const ROOT_REDUCER = new InjectionToken<any>('Root Reducer', { factory: () => (reducers) });

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(ROOT_REDUCER),
    ButtonsModule.forRoot(),
    SignalRModule.forRoot(createConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    VUTranslateModule,
    CookieModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(routes),
    BsDatepickerModule.forRoot(),
    NgxSelectModule,
    LoadingSpinnerModule,
    SharedModule.forRoot(),
    ComponentsModule,
    ReceiptModule,
    PaymentCashModule,
    PaymentCardModule,
    WorkflowModule,
    SimulatorModule,
    LanguagesModule,
    ExternalPaymentModule,
    ExternalTicketModule,
    RFIDCardModule,
    BarcodeModule,
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    AppComponent,
    BackButtonComponent,
    DummyComponent,
    TicketParametersComponent,
    PageHeaderComponent,
    PageHeaderTitleComponent,
    RootComponent,
    TimeoutModalComponent,
    TicketInfoTwoSidedComponent,
    AutofocusDirective,
    PageMessageComponent,
    NumberSpinnerComponent,
    DatapickerRangeComponent,
    SelectComponent,
    DatapickerComponent,
    NotificationForEntranceComponent,
    PrintModalComponent,
    BaseScanCardModalComponent,
    InfoModalComponent,
    NumberValidator,
    AbortButtonComponent,
    NotificationMessageComponent,
    BasketButtonComponent,
    MessageModalComponent,
    ExternalUseBaseComponent,
  ],
  entryComponents: [
    TimeoutModalComponent,
    TicketInfoTwoSidedComponent,
    TicketParametersComponent,
    NotificationForEntranceComponent,
    PrintModalComponent,
    BaseScanCardModalComponent,
    InfoModalComponent,
    MessageModalComponent,
  ],
  bootstrap: [
    AppComponent,
  ],
  providers: [
    {
      useValue: '/store',
      provide: APP_BASE_HREF,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [ConfigurationService],
      // deps: [BootstrapService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: GlobalSettings.getCultureName(),
    },
    PingStateService,
  ],
})
export class AppModule { }
