import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseModalComponent } from 'src/app/components/general/base/base-modal-component';
import { RechargeCardService } from 'src/app/modules/recharge-card/services/recharge-card.service';

@Component({
  selector: 'app-rfid-card-scan-modal',
  templateUrl: './rfid-card-scan-modal.component.html',
  styleUrls: ['./rfid-card-scan-modal.component.css']
})
export class RfidCardScanModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  private loadCardInformationSubscription: Subscription;

  constructor(
    private rechargeCardService: RechargeCardService,
  ) {
    super();
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.usubscribeLoadCardInformationSubscription();
  }

  onRfidCardScanned(barcode: string): void {

    this.usubscribeLoadCardInformationSubscription();
    this.loadCardInformationSubscription = this.rechargeCardService.loadCardInformation(barcode).subscribe(cardInformation => {
      if (cardInformation && cardInformation.name) {
        this.close(barcode);
      }
    });
  }

  usubscribeLoadCardInformationSubscription(): void {
    if (this.loadCardInformationSubscription) {
      this.loadCardInformationSubscription.unsubscribe();
      this.loadCardInformationSubscription = null;
    }
  }

}
