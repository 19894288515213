export enum PostSaleTypeEnum {
  PrintCreditCardReceipt = 'PrintCreditCardReceipt',
  WaitingForMoney = 'WaitingForMoney',
  ProducingRfidCard = 'ProducingRfidCard',
  SaveOrder = 'SaveOrder',
  CheckExitRole = 'CheckExitRole',
  PrintTickets = 'PrintTickets',
  PrintReceipt = 'PrintReceipt',
  ReleasingRfidCard = 'ReleasingRfidCard',
  WaitingForRfidCardRemoval = 'WaitingForRfidCardRemoval',
  OpenGate = 'OpenGate',
  Thanks = 'Thanks',
}
