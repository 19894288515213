import { Injectable, Injector, EventEmitter } from '@angular/core';
import { Money, CreditCard, DtoVuConfiguration } from '../../lib/lib';
import { BannerConfiguration } from '../../lib/lite/banner-configuration';
import { SaleService } from '../sale.service';
import { LiteDisplayStepsService } from './lite-display-steps.service';
import { StepConfigurationType } from '../../lib/lite/step-configuration-type';
import { DispatcherService } from '../dispatcher.service';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { AdditionalPropertiesConfigurationService } from '../configuration/additional-properties-configuration.service';
import { DemoPaymentService } from '../payment/demo-payment.service';
import { Subscription } from 'rxjs';

@Injectable()
export class LiteDisplayService {

  private dispatcherService: DispatcherService;
  private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;
  private vuCommunicationService: VuCommunicationService;
  private demoPaymentService: DemoPaymentService;

  private vuConfigurationChangedSubscription: Subscription;

  private _acceptedCoins: Money[];
  private _acceptedCreditCards: CreditCard[];

  eventRightButtonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    injector: Injector,
    private saleService: SaleService,
    private liteDisplayStepsService: LiteDisplayStepsService,
  ) {
    this.dispatcherService = injector.get(DispatcherService);
    this.additionalPropertiesConfigurationService = injector.get(AdditionalPropertiesConfigurationService);
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.demoPaymentService = injector.get(DemoPaymentService);
  }

  start() {
    if (this.vuConfigurationChangedSubscription)
      this.vuConfigurationChangedSubscription.unsubscribe();
    this.vuConfigurationChangedSubscription = this.vuCommunicationService.vuConnection.eventVuConfigurationChanged.subscribe(() => this.onVuConfigurationChanged());
    this.reloadTurnstileSide();
  }

  stop() {
    if (this.vuConfigurationChangedSubscription)
      this.vuConfigurationChangedSubscription.unsubscribe();
  }

  private onVuConfigurationChanged() {
    this.reloadAcceptedCash();
    this.reloadAcceptedCards();
    this.reloadTurnstileSide();
  }

  reloadAcceptedCash() {
    if (this.additionalPropertiesConfigurationService.isDemoMode) {
      this._acceptedCoins = [
        new Money(0.10, 'EUR'),
        new Money(0.20, 'EUR'),
        new Money(0.50, 'EUR'),
        new Money(1.00, 'EUR'),
        new Money(2.00, 'EUR')
      ];
      return;
    }

    this.vuCommunicationService.vuHttp.getAcceptedCash(this.orderAmount).then(
      acceptedCash => {
        this._acceptedCoins = acceptedCash.coins;
      }
    );
  }

  reloadAcceptedCards() {
    if (this.additionalPropertiesConfigurationService.isDemoMode) {
      this._acceptedCreditCards = [
        new CreditCard('EC'),
        new CreditCard('Maestro'),
        new CreditCard('MasterCard'),
        new CreditCard('Apple'),
        new CreditCard('GPay'),
      ];
      return;
    }

    this.vuCommunicationService.vuHttp.getAcceptedCreditCards().then(
      acceptedCreditCards => {
        this._acceptedCreditCards = acceptedCreditCards;
      }
    );
  }

  reloadTurnstileSide() {
    const entryLeftStep = this.liteDisplayStepsService.findStep(StepConfigurationType.EntryLeft);
    const entryRightStep = this.liteDisplayStepsService.findStep(StepConfigurationType.EntryRight);
    if (this.additionalPropertiesConfigurationService.turnstileIsLeftSide) {
      if (!entryLeftStep.visible) {
        entryLeftStep.visible = true;
      }
      if (entryRightStep.visible) {
        entryRightStep.visible = false;
      }
    } else {
      if (entryLeftStep.visible) {
        entryLeftStep.visible = false;
      }
      if (!entryRightStep.visible) {
        entryRightStep.visible = true;
      }
    }

    if (
      this.liteDisplayStepsService.currentStepType === StepConfigurationType.EntryLeft ||
      this.liteDisplayStepsService.currentStepType === StepConfigurationType.EntryRight
    ) {
      this.go();
    }
  }

  reloadAmount() {
    this.liteDisplayStepsService.setAmount(this.currentAmount);
  }

  showPayinCoins() {
    this.liteDisplayStepsService.setStepsStates(
      StepConfigurationType.Amount,
      [StepConfigurationType.Coins],
      [StepConfigurationType.Coins, StepConfigurationType.Amount],
      null
    )
  }

  printTicket() {
    this.liteDisplayStepsService.selectAndActivate(StepConfigurationType.Print)
  }

  changeCoins() {
    this.liteDisplayStepsService.selectAndActivate(StepConfigurationType.Change)
  }

  go() {
    if (this.additionalPropertiesConfigurationService.turnstileIsLeftSide) {
      this.liteDisplayStepsService.selectAndSuccess(StepConfigurationType.EntryLeft);
    } else {
      this.liteDisplayStepsService.selectAndSuccess(StepConfigurationType.EntryRight);
    }
  }

  rfidAccessDenied() {
    this.liteDisplayStepsService.selectAndError(StepConfigurationType.RFID);
  }

  get acceptedCoins(): Money[] {
    return this._acceptedCoins;
  }

  get acceptedCards(): CreditCard[] {
    return this._acceptedCreditCards;
  }

  get banner(): BannerConfiguration {
    return null;
  }

  updateAmountToPay(amountToPay: Money) {
    this.liteDisplayStepsService.setAmount(amountToPay);
  }

  reset() {
    this.liteDisplayStepsService.setStepsStates(
      StepConfigurationType.Amount,
      null,
      [StepConfigurationType.Coins, StepConfigurationType.Amount, StepConfigurationType.Card],
      null
    );
    this.reloadAcceptedCash();
    this.reloadAcceptedCards();
    this.reloadTurnstileSide();
  }

  onCoinsClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    if (currentStep != StepConfigurationType.Amount) {
      return;
    }

    if (this.liteDisplayStepsService.isPayByCoins && this.additionalPropertiesConfigurationService.isDemoMode) {
      this.demoPayment();
    } else {
      this.leftButtonClick();
    }
  }

  onCardsClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    if (currentStep != StepConfigurationType.Amount) {
      return;
    }

    if (this.liteDisplayStepsService.isPayByCard && this.additionalPropertiesConfigurationService.isDemoMode) {
      this.demoPayment();
    } else {
      this.rightButtonClick();
    }
  }

  leftButtonClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
      case StepConfigurationType.Change:
      case StepConfigurationType.EntryLeft:
      case StepConfigurationType.EntryRight:
        this._backButtonEvent();
        break;
    }
  }

  rightButtonClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
        this.eventRightButtonClick.emit();
        break;
    }
  }

  get orderAmount(): Money {
    return this.saleService.order.amountTotal.clone();
  }

  get currentAmount(): Money {
    let money = this.orderAmount;
    money.value = money.value - this.saleService.order.amountReceived.value;
    return money;
  }

  showLeftButton() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
      case StepConfigurationType.EntryLeft:
      case StepConfigurationType.EntryRight:
        return true;
      case StepConfigurationType.Change:
        return this.saleService.paymentSession.isCancelled;
      default:
        return false;
    }
  }

  showRightButton() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
        return !this.liteDisplayStepsService.isPaymentActive;
      case StepConfigurationType.Change:
        return !this.saleService.paymentSession.isCancelled;
      case StepConfigurationType.Print:
        return this.additionalPropertiesConfigurationService.isDemoMode;
      default:
        return false;
    }
  }

  _backButtonEvent() {
    this.dispatcherService.onBackButtonClick();
  }

  activePaymentByCoins() {
    this.liteDisplayStepsService.activeStep(StepConfigurationType.Coins, true);
  }

  activePaymentByCard() {
    this.liteDisplayStepsService.activeStep(StepConfigurationType.Card, true);
  }

  private demoPayment() {
    this.demoPaymentService.onDenominationClick();
  }

  get isCoinsVisible(): boolean {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    return currentStep === StepConfigurationType.Amount && !this.liteDisplayStepsService.isPayByCard;
  }

  get isCardsVisible(): boolean {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    return currentStep === StepConfigurationType.Amount && !this.liteDisplayStepsService.isPayByCoins;
  }
}
