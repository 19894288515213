export enum WorkflowStepType {
  None = 'None',
  Wait = 'Please wait...',
  CancellingOrder = 'Cancelling the order',
  PaymentReturn = 'Returning {0}. Returned {1}',
  AmountReached = 'Expected amount {0}. Paid {1}',
  ChangeReturn = 'Returning {0} in change. Returned {1}',
  ReceiptPrinting = 'Printing a receipt',
  CreditCardReceiptPrinting = 'Printing a card receipt',
  TicketPrinting = 'Printing tickets',
  CardDispenserProducing = 'Creating a card',
  SaveOrder = 'Saving the order',
  CardDispenserReleasing = 'Releasing a card',
  GateReadBarcode = 'Open the gate',
  GateWaitingForEnter = 'Waiting until the customer enters the room',
  UnexpectedOperation = 'Unexpected operation',
  InvalidTicket = '{0}',
  AmountPaid = 'Paid amount {0}',
  RevertingCardTerminalTransaction = 'Reverting card terminal transaction',
  WaitingForRfidCardRemoval = 'Waiting for card removal',
}
