import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RfidCardScanComponent } from './components/rfid-card-scan/rfid-card-scan.component';
import { ScanRfidCardImageComponent } from './components/scan-rfid-card-image/scan-rfid-card-image.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule } from '@ngx-translate/core';
import { RfidCardScanModalComponent } from './components/rfid-card-scan-modal/rfid-card-scan-modal.component';

@NgModule({
  declarations: [
    ScanRfidCardImageComponent,
    RfidCardScanComponent,
    RfidCardScanModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    InlineSVGModule.forRoot({ baseUrl: '' }),
    TranslateModule,
  ],
  exports: [
    ScanRfidCardImageComponent,
    RfidCardScanComponent,
    RfidCardScanModalComponent,
  ],
  entryComponents: [
    RfidCardScanModalComponent,
  ],
})
export class RFIDCardModule { }
