import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


export enum MessageType {
  ButtonBackClicked,
  Back,
  ButtonTicketClicked,
  ButtonSaleShopClicked,
  ButtonInfoClicked,
  BackButtonVisualItemInfoClicked,

  MachineHardReset,
  MachineHardResetRoot,
  MachineTimeoutModalCancel,
  VisualItemLeafSelected,
  PageHeaderSet,

  ToPaymentCash,
  ToPaymentCard,
  TicketOvertimePayment,
  TicketUse,
  TicketReturnAmount,
  TicketActivation,
  ToBasket,

  LanguageChanged,
  LanguageButtonActivate,

  OpenGate,
  OpenGateComplete,
  OpenGateStop,

  DisplayModeScreenUpdate,
  ToDisplayModePayment,
  DisplayModePaymentComplete,
  DisplayModeRefundComplete,
  ButtonAbortClicked,
  ToDisplayModeCardPayment,
  DisplayModeCardPaymentComplete,

  ButtonMoneyExchangeClicked,

  ExternalPayout,
  ExternalCardRecharge,
  ConfirmPayment,
}

export class Message {
  messageType: MessageType;
  info: any;
  constructor(messageType: MessageType, info: any = null) {
    this.messageType = messageType;
    this.info = info;
  }
}

@Injectable()
export class MessageService {
  private subject = new Subject<Message>();

  sendMessage(message: Message) {
    this.subject.next(message);
  }

  getSubscription(): Observable<Message> {
    return this.subject.asObservable();
  }
}
