export class CardDispenserActionResult {
  isFailed: boolean;
  barcode: string;
  isAvailable: boolean;

  constructor(
    isFailed: boolean,
    barcode: string,
    isAvailable: boolean
  ) {
    this.isFailed = isFailed;
    this.barcode = barcode;
    this.isAvailable = isAvailable;
  }

  static fromJson(data: any): CardDispenserActionResult {
    if (data == null || data.id === -1) {
      return null;
    }
    const obj = data;
    return new CardDispenserActionResult(
      obj.isFailed,
      obj.barcode,
      obj.isAvailable,
    );
  }

  public toString() {
    let s = `isFailed: '${this.isFailed}'. `;
    s += `Barcode: '${this.barcode}'. `;
    s += `isAvailable: '${this.isAvailable}'. `;
    return s;
  }
}
