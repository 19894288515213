<div class="container entire-width">
  <div class="row">
    <div class="col-lg-6" align="center">
      <a type="button" class="btn button-base button-strict-large btn-item" (click)="onSaleButtonClick()">{{ 'Buy' |
        translate }}</a>
        <div *ngIf="isMoneyExchangeVisibleButton" class="col-lg-6" align="center">
          <a type="button" class="btn button-base button-strict-large btn-item" (click)="onMoneyExchangeButtonClick()">{{ 'Money Exchange' | translate }}</a>
      </div>
    </div>
    <div class="col-lg-6" align="center">
      <a type="button" class="btn button-base button-strict-large btn-item" (click)="onTicketButtonClick()">{{
        'Ticket Information' | translate }}</a>
    </div>
  </div>
</div>
