import { Component, OnInit, OnDestroy } from '@angular/core';
import { Money, CashType } from '../../../lib/lib';
import { MoneyExchangeStateService } from '../../../services/money-exchange/money-exchange-state.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-accepting-cash-info',
  templateUrl: './accepting-cash-info.component.html',
  styleUrls: ['./accepting-cash-info.component.css']
})
export class AcceptingCashInfoComponent implements OnInit, OnDestroy {

  canCashBeAccepted = true;
  private backButtonVisible: boolean;

  constructor(
    private moneyExchangeStateService: MoneyExchangeStateService,
    private dispatcherService: DispatcherService,
  ) {
  }

  ngOnInit() {
    this.dispatcherService.pageHeaderSet('Money Exchange');
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = true;
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
  }

  get acceptedCoins(): Money[] {
    return this.moneyExchangeStateService.acceptedCash ? this.moneyExchangeStateService.acceptedCash.coins : [];
  }

  get acceptedBanknotes(): Money[] {
    return this.moneyExchangeStateService.acceptedCash ? this.moneyExchangeStateService.acceptedCash.banknotes : [];
  }

  get cashTypeCoin(): CashType {
    return CashType.Coin;
  }

  get cashTypeBanknote(): CashType {
    return CashType.Banknote;
  }
}
