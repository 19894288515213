import { NgModule } from '@angular/core';
import { ExternalPaymentComponent } from './components/external-payment/external-payment.component';
import { RouterModule } from '@angular/router';
import { ExternalPaymentService } from './services/external-payment.service';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ExternalPaymentRequestSimulatorService } from './services/external-payment-request-simulator.service';

@NgModule({
  declarations: [
    ExternalPaymentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    SharedModule,
  ],
  providers: [
    ExternalPaymentService,
    ExternalPaymentRequestSimulatorService,
  ]
})
export class ExternalPaymentModule { }
