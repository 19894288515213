import { OrderInfo } from './order-info';
import { PaymentInfo } from './payment-info';
export class CardInformation {
  name: string;
  balance: number;
  rechargeProductId: number;
  orders: OrderInfo[];
  payments: PaymentInfo[];

  static creatFromAny(data: any): CardInformation {
    const cardInformation = new CardInformation();
    if (data) {
      cardInformation.name = data.name || '';
      cardInformation.balance = data.balance || 0;
      cardInformation.rechargeProductId = data.recharge_product_id || 0;
      cardInformation.orders = OrderInfo.createFromAnyArray(data.orders);
      cardInformation.payments = PaymentInfo.createFromAnyArray(data.payments);
    }

    return cardInformation;
  }
}
