import { IVuHttp } from '../services/vu/http/vu-http.interface';
import { ILog } from '../services/logging/log.interface';
import { Money } from './lib';
import { PaymentMethod } from './payment/payment-method';

export class RemotePaymentTransaction {
  private log: ILog;
  private vuHttp: IVuHttp;
  private isTransactionOpen = false;
  private internalIsFloatingAmount: boolean;

  constructor(vuHttp: IVuHttp, log: ILog) {
    this.vuHttp = vuHttp;
    this.log = log;
  }

  openRemoteTransaction(paymentMethod: PaymentMethod, amount: Money = null, isFloatingAmount = false) {
    if (this.isTransactionOpen) {
      this.log.error('openRemoteTransaction. Already open');
    }

    const str = PaymentMethod[paymentMethod];
    this.internalIsFloatingAmount = isFloatingAmount;
    this.log.info(`openRemoteTransaction. paymentMethod:  '${str}'. amount: '${amount}'. isFloatingAmount: '${isFloatingAmount}'`);
    this.isTransactionOpen = true;
    if (amount == null) {
      this.log.info('openRemoteTransaction. No need to open');
      return;
    }

    switch (paymentMethod) {
      case PaymentMethod.Cash:
        this.vuHttp.beginPaymentTransaction(amount);
        break;
      case PaymentMethod.PaymentCard:
        this.vuHttp.beginCardPaymentTransaction(amount);
        break;
      default:
        this.log.error(
          `openRemoteTransaction. Not supported type: ${paymentMethod}`
        );
        break;
    }
  }

  closeRemoteTransaction(
    isToCommit: boolean,
    context: string,
    callback: any = null
  ) {
    // TODO: Refactor to return a promise
    const safeCallback = () => {
      if (callback) {
        callback();
      }
    };

    this.log.info(
      `closeRemoteTransaction. isToCommit: '${isToCommit}'. context: '${context}'.`
    );
    if (!this.isTransactionOpen) {
      this.log.warning(`closeRemoteTransaction. Already closed`);
      safeCallback();
      return;
    }
    this.isTransactionOpen = false;
    if (isToCommit) {
      this.vuHttp.commitPaymentTransaction(this.isFloatingAmount);
      safeCallback();
    } else {
      this.revertPaymentTransaction(callback);
    }
  }

  private revertPaymentTransaction(callback: any) {
    const func = isError => {
      const isCallbackDefined = callback !== null;
      this.log.info(
        `revertPaymentTransaction. Finished. isError: '${isError}'. isCallbackDefined: '${isCallbackDefined}'`
      );
      if (isCallbackDefined) {
        callback();
      }
    };

    this.log.info('revertPaymentTransaction. Started.');

    this.vuHttp
      .revertPaymentTransaction()
      .then(x => func(false))
      .catch(x => func(true));
  }

  reset() {
    this.isTransactionOpen = false;
  }

  appendTransactionInfo(info: string) {
    this.vuHttp
      .appendTransactionInfo(info);
  }

  get isOpened(): boolean {
    return this.isTransactionOpen;
  }

  get isFloatingAmount(): boolean {
    return this.internalIsFloatingAmount;
  }
}
