import { Subject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message, MessageService, MessageType } from './message.service';
import { Product, Order, Money, OrderType, PaymentMethod, PaymentSession } from '../lib/lib';
import { IVuConnection } from './vu/connection/vu-connection.interfaces';
import { IVuHttp } from './vu/http/vu-http.interface';
import { LoggingService } from './logging/logging.service';
import { DispatcherService } from './dispatcher.service';
import { VuCommunicationService } from './vu/vu-communication.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TicketParameters } from '../lib/ticket/ticket-parameters';
import { TicketParametersComponent } from '../components/general/ticket-parameters/ticket-parameters.component';
import { CreditCardMessageStore } from '../lib/credit-card/credit-card-message-store';
import { RemotePaymentTransaction } from '../lib/remote-payment-transaction';
import { DisplayItem } from '../lib/display/display-item/display-item';
import { ModalService } from './gui/modal/modal-service';
import { RfidCardScanModalComponent } from '../modules/rfid-card/components/rfid-card-scan-modal/rfid-card-scan-modal.component';
import { BarcodeScanModalComponent } from '../modules/barcode/components/barcode-scan-modal/barcode-scan-modal.component';

@Injectable()
export class SaleService {
  private internalOrder: Order;
  private vuConnection: IVuConnection;
  private vuHttp: IVuHttp;
  private log: LoggingService;
  private dispatcherService: DispatcherService;
  private vuCommunicationService: VuCommunicationService;
  private modalRef: BsModalRef;
  protected messageService: MessageService;
  eventAmountToPayReached: Subject<Money> = new Subject();
  eventMoneyChanged: Subject<Money> = new Subject();
  creditCardMessageStore = new CreditCardMessageStore();
  private internalRemoteTransaction: RemotePaymentTransaction;

  constructor(
    protected injector: Injector,
    private modalService: ModalService,
  ) {
    this.log = this.injector.get(LoggingService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.messageService = this.injector.get(MessageService);
    this.vuConnection = this.vuCommunicationService.vuConnection;
    this.vuHttp = this.vuCommunicationService.vuHttp;
    this.internalRemoteTransaction = new RemotePaymentTransaction(this.vuHttp, this.log);
    this.resetOrder();
    this.dispatcherService.onVisualItemLeafSelectedSubscribe(x => this.onVisualItemLeafSelected(x));
    this.vuConnection.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.messageService.getSubscription().subscribe(message => {
      if (message && message.messageType === MessageType.MachineTimeoutModalCancel) {
        this.modalService.close(this.modalRef);
      }
    });
  }

  get remoteTransaction(): RemotePaymentTransaction {
    return this.internalRemoteTransaction;
  }

  onVisualItemLeafSelected(message: Message): void {
    if (!message || !message.info) {
      return;
    }
    let productPrice: Money = null;
    let product = message.info.data as Product;
    if (!product) {
      const displayItem = message.info as DisplayItem;
      if (displayItem) {
        product = displayItem.product;
        productPrice = displayItem.productPrice;
      }
    }

    if (!Product.isProduct(product)) {
      return;
    }

    if (product.scanCardRequested || product.scanBarcodeRequested) {
      if (this.modalRef) {
        return;
      }

      this.modalRef = this.modalService.show(
        product.scanCardRequested ? RfidCardScanModalComponent : BarcodeScanModalComponent,
        {},
        (barcode: string) => {
          this.modalRef = null;
          if (barcode) {
            this._addProductWithTicketParameters(product, productPrice, barcode);
          } else {
            this.dispatcherService.onBackButtonClick();
          }
        }
      );
    } else {
      this._addProductWithTicketParameters(product, productPrice);
    }
  }

  private _addProductWithTicketParameters(product: Product, productPrice: Money, barcode: string = ''): void {
    const ticketParameters = new TicketParameters();
    ticketParameters.groupSize = product.groupSizeMin;

    if (product.ticketParametersRequested) {
      if (this.modalRef) {
        return;
      }

      const data = {
        ticketParameters,
        product
      };

      this.modalRef = this.modalService.show(
        TicketParametersComponent,
        data,
        (accepted: boolean) => {
          this.modalRef = null;
          if (accepted) {
            this._addProduct(
              product,
              productPrice,
              barcode,
              ticketParameters
            );
          } else {
            this.dispatcherService.onBackButtonClick();
          }
        }
      );
    } else {
      this._addProduct(product, productPrice, barcode, ticketParameters);
    }
  }

  private _addProduct(product: Product, productPrice: Money, barcode: string, ticketParameters: TicketParameters = null): void {
    const line = this.order.addProduct(product, 1, productPrice, barcode, ticketParameters);

    const additionalProductIds = product.additionalProductIds;
    if (!additionalProductIds || additionalProductIds.length === 0) {
      return;
    }

    if (line.minQuantity === 0) {
      line.minQuantity = 1;
    }

    this.vuHttp.getProductsByIds(additionalProductIds)
      .then(products => {
        products.forEach(item => {
          if (additionalProductIds.includes(item.id)) {
            let quantity = 0;
            if (item.additionalProperties && item.additionalProperties.qty_min) {
              quantity = item.additionalProperties.qty_min;
            }
            const orderLine = this.order.addProduct(item, quantity);
            orderLine.required = true;
          }
        });
      });
  }

  onMoneyChanged(money: Money): void {
    this.log.info(`SaleService. onMoneyChanged. '${money}'`);
    this.order.registerMoney(money);
    this.eventMoneyChanged.next(money);
  }

  resetOrder(): void {
    this.log.info('SaleService. Order reset.');
    this.internalOrder = new Order();
    this.dispatcherService.shopStateOrderUid(this.order.uid);
    this.remoteTransaction.reset();
  }

  ticketReturnAmount(product: Product, amount: Money, orderId: number, barcode: string, isDisplayMode: boolean): void {
    if (!isDisplayMode) {
      this.resetOrder();
    }

    this.log.info(`SaleService. ticketReturnAmount. product. ${product}`);
    this.log.info(`SaleService. ticketReturnAmount. amount: ${amount}. orderId: ${orderId}. barcode: ${barcode}.`);
    const order = this.order;
    if (orderId) {
      order.originalOrderId = orderId;
      order.type = OrderType.SaleRefund;
    }
    const negateAmount = amount.negate();
    const orderLine = order.addProduct(product, 1, negateAmount, barcode);
    if (product.subProducts) {
      product.subProducts.forEach(subProduct => {
        order.addProduct(subProduct, 1, subProduct.price.negate());
      });
    }

    orderLine.updateProperties('rfid-refund', true);

    this.log.info(`SaleService. ticketReturnAmount. order. ${order}`);
    this.dispatcherService.ticketReturnAmount(isDisplayMode);
  }

  get order(): Order {
    return this.internalOrder;
  }

  openPaymentSession(paymentMethod: PaymentMethod, amount?: Money): void {
    this.log.info(`SaleService. openPaymentSession. paymentMethod: '${PaymentMethod[paymentMethod]}'. amount: '${amount}'.`);
    this.order.paymentMethod = paymentMethod;
    this.remoteTransaction.openRemoteTransaction(paymentMethod, amount);
    this.order.openSession();
  }

  openPaymentSessionWithFloatingAmount(paymentMethod: PaymentMethod, minAmount: Money, maxAmount: Money): void {
    this.log.info('SaleService. openPaymentSessionWithFloatingAmount.' +
      `paymentMethod: '${PaymentMethod[paymentMethod]}'. minAmount: '${minAmount}'. maxAmount: '${maxAmount}'.`);
    this.order.paymentMethod = paymentMethod;
    const amount = maxAmount;
    this.remoteTransaction.openRemoteTransaction(paymentMethod, amount, true);
    this.order.openSession(minAmount, maxAmount);
  }

  closePaymentSession(): void {
    this.order.closeSession();
    this.resetOrder();
  }

  get paymentSession(): PaymentSession {
    return this.order.paymentSession;
  }

  get orderId(): number {
    return this.order == null ? -1 : this.order.id;
  }

  addRfidCardBarcodeInOrder(barcode: string): void {
    if (this.order.hasRfidCard) {
      this.order.addRfidCardBarcode(barcode);
    } else {
      this.log.warning('addRfidCardBarcodeInOrder. Current order doesn`t contain RFID card');
    }
  }

  get buyOrderInOneClick(): boolean {
    if (!this.order || this.order.orderLines.length !== 1 || !this.order.orderLines[0].product) {
      return false;
    }

    return this.order.orderLines[0].product.buyInOneClick;
  }

  get orderInProgress(): boolean {
    return this.order && this.order.orderLines && this.order.orderLines.length !== 0;
  }
}
