import { WorkflowStep } from './workflow-step';
import { WorkflowStepType } from './workflow-step-type';
import { WorkflowStepState } from './workflow-step-state';

export class Workflow {
  name: string;
  steps: WorkflowStep[] = [];

  static fromOther(other: Workflow): Workflow {
    if (other == null) {
      throw new Error('Other workflow cannot be null');
    }
    return Object.assign(new Workflow(other.name, WorkflowStepType.None), other);
  }

  constructor(name: string, stepType: WorkflowStepType, ...args: string[]) {
    this.name = name;
    if (stepType !== WorkflowStepType.None) {
      this.addStep(new WorkflowStep(stepType, ...args));
    }
  }

  addStep(step: WorkflowStep) {
    if (this.steps == null) {
      this.steps = [];
    }
    this.steps.push(step);
  }

  deleteLastStep() {
    if (this.steps == null) {
      return;
    }
    const index = this.steps.length - 1;
    if (index > -1) {
      this.steps.splice(index, 1);
    }
  }
  get lastStepState(): WorkflowStepState {
    const index = this.lastStepIndex;
    return index < 0 ? WorkflowStepState.None : this.steps[index].state;
  }

  set lastStepState(state: WorkflowStepState) {
    if (this.lastStep != null) {
      this.lastStep.state = state;
    }
  }

  lastStepUpdateArgs(...args: string[]) {
    if (this.lastStep != null) {
      this.lastStep.args = args;
    }
  }

  get lastStep(): WorkflowStep {
    const index = this.lastStepIndex;
    return this.lastStepIndex > -1 ? this.steps[index] : null;
  }

  get lastStepIndex(): number {
    return this.steps == null ? -1 : this.steps.length - 1;
  }
}
