import { DictionaryHelper } from '../dictionary-helper';

export class OrderSaveResult {
  orderId: number;
  orderUid: string;
  isSuccess: boolean;
  error: string;
  properties: any;
  private internalDictionaryHelper: DictionaryHelper;

  static fromOther(other: any) {
    return new OrderSaveResult(other.orderId,
      other.orderUid,
      other.isSuccess,
      other.error,
      other.properties);
  }

  constructor(
    orderId: number,
    orderUid: string,
    isSuccess: boolean,
    error: string,
    properties: any = {}
  ) {
    this.orderId = orderId;
    this.orderUid = orderUid;
    this.isSuccess = isSuccess;
    this.error = error;
    this.properties = properties;
    this.internalDictionaryHelper = new DictionaryHelper(this.properties);
  }

  get barcodesOfCreatedTickets(): string[] {
    return this.internalDictionaryHelper.getStringArray('barcodes_of_created_tickets');
  }

  toString(): string {
    let result = `orderId: ${this.orderId}.`;
    result += `orderUid: ${this.orderUid}.`;
    result += `isSuccess: ${this.isSuccess}.`;
    result += `error: ${this.error}`;
    result += `properties: ${this.internalDictionaryHelper}`;
    return result;
  }
}
